import ConfirmAccountSuccess from 'pages/auth/ConfirmAccountSuccess';
import ConfirmAccount from 'pages/auth/ConfirmAccount';
import RequestResetPassword from 'pages/auth/RequestResetPassword';
import ResetPasswordSuccess from 'pages/auth/ResetPasswordSuccess';
import ResetPassword from 'pages/auth/ResetPassword';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import RouteGuard from 'components/shared/route-guard';
import PageNotFound from 'pages/PageNotFound';
import ConfirmEmail from 'pages/auth/ConfirmEmail';
import Login from 'pages/auth/Login';
import LoginWithToken from 'pages/auth/LoginWithToken';
import SignUp from 'pages/auth/SignUp';
import Welcome from 'pages/auth/Welcome';
import ApplyForClaim from 'pages/claim/ApplyForClaim';
import ClaimDetails from 'pages/dashboard/claims/ClaimDetails';
import Claims from 'pages/dashboard/claims/Claims';
import DashboardHome from 'pages/dashboard/home/DashboardHome';
import PlanDetails from 'pages/dashboard/plans/PlanDetails';
import Plans from 'pages/dashboard/plans/Plans';
import Settings from 'pages/dashboard/settings/Settings';
import ApplyForPlan from 'pages/plan/ApplyForPlan';
import PlanPdf from 'pages/plan/pdf';
import NavigationTracker from 'common/utils/user-tracker/navigation';
import ReviewClaim from 'pages/claim/ReviewClaim';
import EmailLoginExchange from 'pages/auth/EmailLoginExchange';
import EmailLoginRequest from 'pages/auth/EmailLoginRequest';
import AttachPlan from 'pages/auth/AttachPlan';

const AppRouter = () => {
  return (
    <Router>
      <NavigationTracker />
      <Routes>
        {/* NOT FOUND/ INVALID ROUTE */}
        <Route path='*' element={<PageNotFound />} />

        {/* Temporary routes that will probably get moved */}
        <Route path='/dashboard/plans/:planId/pdf' element={<PlanPdf />} />

        {/* This is how an admin will impersonate a user */}
        <Route path='/use-login-token' element={<LoginWithToken />} />

        {/* AUTH */}
        <Route path='/login-with-password' element={<Login />} />
        <Route path='/login' element={<EmailLoginRequest />} />
        <Route
          path='/email-login-request'
          element={<Navigate to='/login' replace />}
        />
        <Route path='/email-login-exchange' element={<EmailLoginExchange />} />
        <Route path='/attach-plan' element={<AttachPlan />} />

        {/* ROUTE GUARD */}
        <Route element={<RouteGuard />}>
          <Route
            path='/reset-password-success'
            element={<ResetPasswordSuccess />}
          />
          <Route
            path='/reset-password-request'
            element={<RequestResetPassword />}
          />
          <Route path='reset-email' element={<ResetPassword />} />
          <Route path='/signup' element={<SignUp />} />
          <Route
            path='/account-created-success'
            element={<ConfirmAccountSuccess />}
          />
          <Route path='/confirm-account' element={<ConfirmAccount />} />
          <Route path='/confirm-email' element={<ConfirmEmail />} />

          {/* DASHBOARD WELCOME */}
          <Route path='/welcome' element={<Welcome />} />

          {/* DASHBOARD HOME */}
          {/* Redirect to the preferred route */}
          <Route path='/' element={<Navigate to='/dashboard/home' replace />} />
          <Route
            path='/dashboard'
            element={<Navigate to='/dashboard/home' replace />}
          />
          {/* Preferred route */}
          <Route path='/dashboard/home' element={<DashboardHome />} />

          {/* DASHBOARD CLAIMS */}
          <Route
            path='/dashboard/claims/submit/:claimId'
            element={<ApplyForClaim />}
          />
          <Route path='/dashboard/claims/submit' element={<ApplyForClaim />} />
          <Route path='/dashboard/claims' element={<Claims />} />
          <Route path='/dashboard/claims/:claimId' element={<ClaimDetails />} />
          <Route
            path='/dashboard/claims/:claimId/review'
            element={<ReviewClaim />}
          />

          {/* DASHBOARD PLANS */}
          <Route path='/dashboard/plans/apply' element={<ApplyForPlan />} />
          <Route path='/dashboard/plans' element={<Plans />} />
          <Route path='/dashboard/plans/:planId' element={<PlanDetails />} />

          {/* DASHBOARD SETTINGS */}
          <Route path='dashboard/settings' element={<Settings />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
