import { Flex, Tag, Text } from '@chakra-ui/react';
import { Radio } from '@chakra-ui/react';
import { formatAsPercentage } from 'common/utils/misc';
import { planPaymentMode } from 'utils/enums';

const PaymentPlanCard = ({ amount, discount, title, subtitle, value, recurring=true }) => {
  return (
    <Flex className='px-6 py-3 gap-3 sm:gap-6 w-full flex-col sm:flex-row sm:items-center sm:justify-between'>
      {/* cost section */}
      <Radio value={value} size='lg'>
        <Flex className='ml-3 w-full flex-col'>
          <Flex className='text-base font-medium'>{title}</Flex>
          <Text className='text-base text-gray-500'>{subtitle}</Text>
        </Flex>
      </Radio>

      {/* Divider for mobile */}
      <hr className='ml-10 max-w-[2rem] sm:hidden' />

      {/* Price section */}
      <Flex className={`flex gap-3 items-center ml-10 sm:ml-0`}>
        {discount ? (
          <Tag
            colorScheme='blue'
            className='w-fit h-fit flex py-1 px-3 rounded-full text-sm font-medium capitalize order-last sm:order-first'
          >
            Save {formatAsPercentage(discount)}%
          </Tag>
        ) : null}
        <Flex className='flex-col'>
          <Text>
            <span className='align-baseline text-xl font-medium'>{amount}</span>
            <span className='align-baseline text-md text-gray-500'>
              {amount ? recurring ? ' /mo' : ' total' : null}
            </span>
          </Text>
          {amount && recurring ? (<span className='text-gray-500 text-sm'>Including monthly rent</span>) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PaymentPlanCard;
