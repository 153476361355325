import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import Tracker from 'components/auth/welcome/tracker';
import { PlanApplyButton } from 'components/plan-apply/plan-apply-modal';
import {
  useGetAllPlansQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';

const Welcome = () => {
  const navigate = useNavigate();

  const {
    data: plansData,
  } = useGetAllPlansQuery();

  return (
    <>
      <BgLayout>
        {plansData?.length > 0 ? (
          <>
            <div className='flex flex-col text-center gap-6'>
              <h1 className='font-display tracking-tight text-4xl'>
                Your Email Has Been Verified
              </h1>

              <p className='text-base text-gray-500 font-normal'>
                You can now access your dashboard and start managing your
                plans, claims, account, and more.
              </p>
            </div>
            <div className='flex w-full flex-col gap-3'>
              <Button
                linkId='viewPlansBtn'
                title='Go to Dashboard'
                onClick={() => navigate('/dashboard/home')}
              />
              <Button
                theme='secondary'
                linkId='speakWithExpertBtn'
                title='Speak with ReLease Support'
                onClick={() =>
                  window.open('mailto:members@releaserent.com', '_blank')
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className='flex flex-col text-center gap-6'>
              <h1 className='font-display tracking-tight text-4xl'>
                Welcome to ReLease
              </h1>

              <p className='text-base text-gray-500 font-normal'>
                Our flexible leasing platform gives you the freedom to move on
                your own terms. Sign your lease with the confidence of knowing
                you can move or cancel anytime, for any reason.
              </p>
            </div>
            <div className='flex w-full flex-col gap-3'>
              <PlanApplyButton />
              <Button
                theme='secondary'
                linkId='speakWithExpertBtn'
                title='Speak with ReLease Support'
                onClick={() =>
                  window.open('mailto:members@releaserent.com', '_blank')
                }
              />
            </div>
          </>
        )}
      </BgLayout>
      <Tracker />
    </>
  );
};

export default Welcome;
