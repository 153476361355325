import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  selectPlanID,
  selectCoverageSelection,
} from 'redux/features/plans/plansSlice';
import {
  useAddSetupIntentMutation,
  useMakePaymentMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { coverageTypes } from 'utils/enums';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { SetupCard } from 'common/components/setup-card';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import ModalBgLayout from 'common/layouts/modal-bg';

const FinishSetupIntent = ({
  setupIntentID,
  setPaymentSuccess,
  onNextClick,
  removeSetupIntent,
}) => {
  // setupIntent is an id for a setupIntent
  // Can use it to add it to the user:
  // api/v1/tenants/me/add-setup-intent/:setupIntentID

  // HOOKS
  const [searchParams] = useSearchParams();
  const planIDParam = searchParams.get('planId');
  const draftPlanID = useSelector(selectPlanID);
  const planID = planIDParam || draftPlanID;
  const coverageSelection = useSelector(selectCoverageSelection);
  const { trackCustomEvent, trackPageLoad } = useUserTracker();
  useEffect(() => {
    trackPageLoad('PlanStepSetPaymentPaymentAttempt');
  }, []);
  const [
    addSetupIntent,
    {
      isLoading: setupIntentLoading,
      isSuccess: setupIntentSuccess,
      isError: setupIntentError,
    },
  ] = useAddSetupIntentMutation();
  const [
    makePayment,
    {
      isLoading: isPayLoading,
      isError: paymentError,
      isSuccess: paymentSuccess,
    },
  ] = useMakePaymentMutation();

  // SIDE EFFECT
  useEffect(() => {
    addSetupIntent(setupIntentID)
      .unwrap()
      .then()
      .catch((error) => {
        toast.error(error.message, { toastId: 'setupIntentFailed' });
        console.error(error);
      });
  }, []);
  useEffect(() => {
    if (setupIntentSuccess) {
      let choices = {};
      const leaveAnytime = coverageSelection[coverageTypes.LEAVE_ANYTIME];
      const cancelAnytime = coverageSelection[coverageTypes.CANCEL_ANYTIME];
      if (leaveAnytime) {
        choices[coverageTypes.LEAVE_ANYTIME] = {
          type: leaveAnytime,
        };
      }

      if (cancelAnytime) {
        choices[coverageTypes.CANCEL_ANYTIME] = {
          type: cancelAnytime,
        };
      }
      // make call
      makePayment({ planID, choices })
        .unwrap()
        .then(() => {
          trackCustomEvent('paymentAttempt', {
            choices,
            isSuccess: true,
            planId: planID,
          });
          setPaymentSuccess(true);
          toast.success('Your payment was processed successfully', {
            toastId: 'paymentSuccess',
          });
          // Redirect user to the next page automatically in 5 seconds
          setTimeout(() => {
            onNextClick();
            removeSetupIntent();
          }, 5000); // Delay of 5 seconds (5000 ms)
        })
        .catch((error) => {
          trackCustomEvent('paymentAttempt', {
            choices,
            isSuccess: false,
            planId: planID,
          });
          toast.error(
            error?.message || 'We had issues processing your payment',
            { toastId: 'paymentFailed' }
          );
        });
    }
  }, [setupIntentSuccess]);

  return (
    <ModalBgLayout>
      {/* Check for an explicit setup intent error */}
      {setupIntentError ? (
        // Payment Declined
        <SetupCard
          title='We had issues processing your payment'
          subtitle='Please verify your payment method and try again'
          message='We were unable to process your payment. Please update your payment method by clicking the button below and resubmitting.'
          buttonTitle='Update Payment Method'
          onClick={removeSetupIntent}
        />
      ) : // check if setup intent is loading
      setupIntentLoading ? (
        // Payment Processing
        <SetupCard
          isLoading
          title='Verifying your payment information'
          subtitle='Please do not refresh the page'
          message='Please wait while we verify your payment information. Do not refresh the page.'
        />
      ) : // Check if the setup intent is successful
      setupIntentSuccess ? (
        // Payment Error with One Time or Monthly Payment
        paymentError || paymentError ? (
          <SetupCard
            icon={FaCircleXmark}
            title='We had issues processing your payment'
            subtitle='Please verify your payment method and try again'
            message='We were unable to process your payment. Please update your payment method by clicking the button below and resubmitting.'
            buttonTitle='Update Payment Method'
            onClick={removeSetupIntent}
          />
        ) : // Check if the payment is loading
        isPayLoading ? (
          // Payment Processing
          <SetupCard
            isLoading
            title='Processing your payment'
            subtitle='Please do not refresh the page'
            message='Please wait while we process your payment. Do not refresh the page.'
          />
        ) : paymentSuccess || paymentSuccess ? (
          // Give the user confirmation + manual redirect if required
          <SetupCard
            icon={FaCircleCheck}
            title='Your payment was processed successfully'
            subtitle='You will be automatically redirected in 5 seconds'
            message='If you are not automatically redirected in 5 seconds, press the button below to activate your coverage plan.'
            buttonTitle='Activate Your Plan'
            onClick={() => {
              onNextClick();
              removeSetupIntent();
            }}
          />
        ) : null
      ) : null}
    </ModalBgLayout>
  );
};

export default FinishSetupIntent;
