import LogoImage from 'common/components/logo-image';
import WrapInputLabel from 'common/components/wrap-input-label';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { BsTelephone } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi';
import { GoDotFill } from 'react-icons/go';
import { HiOutlineMail } from 'react-icons/hi';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty } from 'common/utils/isEmpty';
import * as Yup from 'yup';
import Tracker from 'components/auth/signup/tracker';
import AuthLabel from 'common/components/auth-label';
import Button from 'common/components/button';
import FormError from 'common/components/form-error';
import Input from 'common/components/input';
import PasswordInput from 'common/components/password-input';
import AuthMainLayout from 'layouts/auth-main';
import {
  useConfirmAccountMutation,
  useRegisterMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { setCredentials } from 'redux/features/auth/authSlice';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from 'utils/regex';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { PhoneNumberInput } from 'common/components/phone-input';
import { ApplyFormField, ApplyFormLayout } from 'common/layouts/apply-form';
import { PasswordRequirements } from 'common/components/password-requirements';
import { Flex } from '@chakra-ui/react';

// TODO remove the confirm-account logic in sign up

const SignUp = () => {
  // STATES
  const [hasSignupCode, setHasSignupCode] = useState(false);

  // HOOKS
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register, { isLoading: isRegisterLoading }] = useRegisterMutation();
  const [confirmAccount, { isLoading: isConfirmingAccount }] =
    useConfirmAccountMutation();
  const {
    trackLink,
    trackIdentity,
    trackFieldPopulate,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        repeatPassword: '',
        signupCode: '',
      }}
      onSubmit={({
        firstName,
        lastName,
        email,
        phone,
        signupCode,
        password,
        repeatPassword,
      }) => {
        if (!isEmpty(userToken)) {
          confirmAccount({
            token: userToken,
            name: `${firstName} ${lastName}`,
            password: password,
            phone: phone,
          })
            .unwrap()
            .then(({ data }) => {
              toast.info(data?.message || 'Success');
              dispatch(
                setCredentials({
                  isLoggedIn: true,
                  token: data?.token,
                  user: data?.user,
                })
              );
              navigate('/account-created-success');
            })
            .catch((error) => {
              toast.error(error?.data?.msg || 'Registration Failed');
            });
        } else {
          let valueCp = {
            firstName,
            lastName,
            email,
            phone,
            signupCode,
            password,
            repeatPassword,
          };
          valueCp.password = '*'.repeat(valueCp.repeatPassword.length);
          valueCp.repeatPassword = '*'.repeat(valueCp.repeatPassword.length);
          trackFormSubmission(valueCp);
          register({
            name: `${firstName} ${lastName}`,
            firstName,
            lastName,
            signupCode,
            email,
            phone,
            password,
          })
            .unwrap()
            .then(({ data }) => {
              toast.info(data?.message || 'Success');
              trackIdentity(data?.user?.id || 'unknown-id', data?.user || {});
              trackFormSubmissionSuccess(valueCp);
              dispatch(
                setCredentials({
                  isLoggedIn: true,
                  token: data?.token,
                  user: data?.user,
                })
              );
              navigate('/confirm-email');
            })
            .catch((error) => {
              toast.error(error?.data?.msg || 'Registration Failed');
              trackFormSubmissionError(
                valueCp,
                error?.data?.msg || 'Registration Failed'
              );
            });
        }
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        phone: Yup.string()
          .required('Phone number is required')
          .test('is-valid-phone', 'Phone number is invalid', (value) => {
            return isValidPhoneNumber(value || '');
          }),
        password: Yup.string()
          .required('Required')
          .min(8, 'Must be at least 8 characters long')
          .matches(NOT_STRICT_DIGIT_REGEX, 'Must contain a number')
          .matches(LOWERCASECASE_REGEX, 'Must contain a lowercase')
          .matches(UPPERCASE_REGEX, 'Must contain an uppercase'),
        repeatPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('password')], 'Passwords must match'),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        setFieldTouched,
        setFieldValue,
      }) => {
        return (
          <AuthMainLayout>
            <div className='px-6 md:px-8 flex flex-col gap-12'>            

              {/* labels */}
              <div className='flex gap-3 items-center mobile:gap-0'>
                <Link to='/login' linkId='signupLoginBtn'>
                  <AuthLabel title='Log In' isActive={false} />
                </Link>
                <AuthLabel title='Create Account' />
              </div>

              {/* Form */}
              <Form className='flex flex-col items-stretch w-full gap-9'>
                {/* caption */}
                <p className='text-4xl font-display tracking-tight'>
                  Create a New ReLease Account
                </p>

                {/* fields container */}
                <ApplyFormLayout>
                  {/*Full name */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>First Name</WrapInputLabel>
                    <Input
                      name='firstName'
                      value={values.firstName}
                      onChange={(e) => {
                        setFieldTouched('firstName');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      placeholderText='First name'
                      Icon={FaRegUser}
                    />
                    <FormError name='firstName' />
                  </ApplyFormField>

                  <ApplyFormField small>
                    <WrapInputLabel isRequired>Last Name</WrapInputLabel>
                    <Input
                      name='lastName'
                      value={values.lastName}
                      onChange={(e) => {
                        setFieldTouched('lastName');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      placeholderText='Last name'
                      Icon={FaRegUser}
                    />
                    <FormError name='lastName' />
                  </ApplyFormField>

                  {/* email */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Email Address:</WrapInputLabel>
                    <Input
                      name='email'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('email');
                        handleChange(e);
                      }}
                      value={values.email}
                      placeholderText='Email address'
                      Icon={HiOutlineMail}
                    />
                    <FormError name='email' />
                  </ApplyFormField>

                  {/* phone number */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Phone Number:</WrapInputLabel>
                    <PhoneNumberInput
                      name='phone'
                      value={values.phone}
                      onChange={(e) => {
                        setFieldValue('phone', e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        trackFieldPopulate('phone');
                      }}
                    />
                    <FormError name='phone' />
                  </ApplyFormField>

                  {/* password */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>New Password:</WrapInputLabel>
                    <PasswordInput
                      name='password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('password');
                        handleChange(e);
                      }}
                      value={values.password}
                      placeholderText='New password'
                    />
                    <FormError name='password' />

                    <PasswordRequirements password={values.password} />
                  </ApplyFormField>

                  {/*Re-enter password */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                      Confirm Password:
                    </WrapInputLabel>
                    <PasswordInput
                      name='repeatPassword'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('repeatPassword');
                        handleChange(e);
                      }}
                      value={values.repeatPassword}
                      placeholderText='Re-enter new password'
                    />
                    <FormError name='repeatPassword' />
                  </ApplyFormField>                  
                </ApplyFormLayout>

                {/* button */}
                <Flex className='flex-col gap-3'>
                  {/* signup code */}
                  {hasSignupCode ? (
                    <Flex className='col-span-2 pt-6 mb-6 border-t border-gray-200'>
                      <ApplyFormField>
                        <WrapInputLabel>Signup Code:</WrapInputLabel>
                        <Input
                          name='signupCode'
                          value={values.signupCode}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldTouched('signupCode');
                            handleChange(e);
                          }}
                          disableIcon={true}
                          placeholderText='Signup code'
                          Icon={BsTelephone}
                        />
                      </ApplyFormField>
                    </Flex>
                  ) : (
                    <Button
                      onClick={() => {
                        setHasSignupCode(true);
                        trackLink('Have a signup code?');
                      }}
                      title='Have a signup code?'
                      theme='secondary'
                      className='col-span-2'
                    />
                  )}
                <Button
                  disabled={
                    !isValid || isRegisterLoading || isConfirmingAccount
                  }
                  loading={isRegisterLoading || isConfirmingAccount}
                  type='submit'
                  title='Sign up'
                />
                <Tracker
                  values={values}
                  errors={errors}
                  touched={touched}
                  formName='signup'
                />
                </Flex>
              </Form>
            </div>
          </AuthMainLayout>
        );
      }}
    </Formik>
  );
};

export default SignUp;
