import Button from 'common/components/button';
import EmptyPage from 'common/components/empty-page';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import React from 'react';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <DashboardWrapperLayout>
      <EmptyPage
        PageIcon={FaTriangleExclamation}
        title='Page Not Found'
        subtitle='The page you are looking for does not exist. Please check the URL or click the button below to go back to your dashboard.'
        buttonText='Go to Dashboard'
        onClick={() => navigate('/dashboard')}
      />
    </DashboardWrapperLayout>
  );
};

export default PageNotFound;
