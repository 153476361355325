import { Flex, Icon, Tag, Text } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'common/utils/isEmpty';
import DownloadPdfButton from 'components/plans/download-button';
import PlanInfoCard from 'components/plans/plan-info-card';
import { useEffect, useMemo, useState } from 'react';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
  useCreateClaimMutation
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useDispatch } from 'react-redux';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import { coverageTypes } from 'utils/enums';
import ContactCard from 'components/shared/contact-card';
import {
  PostPurchaseCoverageCard
} from 'components/coverages/coverage-card';
import CTA_Buttons from 'common/components/cta-buttons';
import { isWithinDateRange } from 'common/utils/dateRange';
import EmptyPage from 'common/components/empty-page';
import { InfoDetail, MemberInfoCard } from 'components/shared/member-info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import { FaExclamationCircle } from 'react-icons/fa';
import { planStatusEnum, statusEnum } from 'common/utils/enums';

const PlanDetails = () => {
  // HOOKS
  const { planId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: singlePlan, isLoading: getSinglePlanLoading } =
    useGetSinglePlanQuery(planId);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();
  const [createClaim, { data: claimData, isLoading: createClaimLoading }] =
    useCreateClaimMutation();

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // Check eligibility of coverages
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const cancelAnytime = singlePlan?.coverage?.cancelAnytime;
  const leaveAnytime = singlePlan?.coverage?.leaveAnytime;

  const hasCancelAnytime = cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    leaveAnytime?.payment?.onetime?.stripe?.id ||
    leaveAnytime?.payment?.subscription?.stripe?.id;

  const leaveAnytimeEligible = useMemo(
    () => hasLeaveAnytime && isWithinDateRange(leaveAnytime, now),
    [now, hasLeaveAnytime, leaveAnytime]
  );

  const cancelAnytimeEligible = useMemo(
    () => hasCancelAnytime && isWithinDateRange(cancelAnytime, now),
    [now, hasCancelAnytime, cancelAnytime]
  );

  const hasLimitedCoverage = useMemo(
    () => leaveAnytime?.endDate < singlePlan?.lease?.endDate,
    [leaveAnytime, singlePlan]
  );

  const address = singlePlan?.lease?.address;

  const concessions = singlePlan?.lease?.concessions?.repayable
    ? (singlePlan?.lease?.concessions?.months ?? 0) *
      (singlePlan?.lease?.monthlyRent ?? 0) *
      (singlePlan?.lease?.concessions?.discount ?? 0)
    : 0;

  const program = singlePlan?.property?.owner?.program?.deductible;

  const ctaButtons = (
    <CTA_Buttons
      disablePrimary={!leaveAnytimeEligible && !cancelAnytimeEligible}
      onPrimaryClick={() => {
        createClaim({
          value: { planID: planId },
        })
          .unwrap()
          .then((x) => {
            navigate(`/dashboard/claims/submit/${x?.data?.id}`);
          });
      }}
      onSecondaryClick={() => window.open('mailto:members@releaserent.com')}
      primaryTitle='Submit a Claim'
      secondaryTitle='Get Help from Support'
    />
  );

  return (
    <DashboardWrapperLayout title={`Plan Details`} buttons={ctaButtons}>
      <Flex className='w-full flex-col gap-12 bg-white'>
        {/* plan card and payment summary */}
        {getSinglePlanLoading ? (
          <EmptyPage
            title='Loading Your Plan'
            subtitle='Please wait while we load your plan details.'
            isLoading={true}
          />
        ) : isEmpty(singlePlan) ? (
          <EmptyPage
            title='No Plan Found'
            subtitle='We could not find any plan details for you.'
          />
        ) : (
          <>
            {/* plan card and info */}
            <Flex className='flex-col gap-4'>
              {/* plan heading and status */}
              <Flex className='gap-4 items-center justify-between'>
                <h2 className='text-lg font-medium'>Your Plan Details</h2>
                {singlePlan?.id && singlePlan?.status === planStatusEnum.ACTIVE ? (
                  <DownloadPdfButton planId={singlePlan?.id} wFull={false} />
                ) : null}
              </Flex>

              <PlanInfoCard plan={singlePlan} member={userDetails} />
            </Flex>

            <PaymentSummaryCard
              plan={singlePlan}
              complete={true}
              card={false}
            />

            <Flex className='flex-col gap-6'>
              <Flex className='flex-col gap-1 w-full items-left'>
                <h2 className='text-lg font-medium'>Covered Lease Agreement</h2>
                <Text className='text-base text-gray-500'>
                  Your plan is limited to the following lease agreement.
                </Text>
              </Flex>
              <MemberInfoCard
                title={
                  <>
                    {address?.streetAddress1}
                    {address?.streetAddress2
                      ? `, ${address?.streetAddress2}, `
                      : ', '}
                    {address?.city}, {address?.state || address?.province}{' '}
                    {address?.zipCode || address?.postalCode}
                  </>
                }
                subtitle={
                  <>
                    {formatDateToHumanDate(singlePlan?.lease?.startDate)} -{' '}
                    {formatDateToHumanDate(singlePlan?.lease?.endDate)}
                  </>
                }
                buttonText='View Lease Agreement'
                onClick={() => {
                  window.open(singlePlan?.lease?.document, '_blank');
                }}
              />
            </Flex>

            {/* plan coverages section */}
            {hasCancelAnytime || hasLeaveAnytime ? (
              <Flex className='flex-col gap-6'>
                {/* coverages heading */}
                <Flex className='flex-col gap-1 w-full items-left'>
                  <h2 className='text-lg font-medium'>Plan Coverages</h2>
                  <Text className='text-base text-gray-500'>
                    Your plan includes the following coverages, deductibles, and
                    exclusions.
                  </Text>
                </Flex>

                {/* coverages */}
                {hasCancelAnytime ? (
                  <PostPurchaseCoverageCard
                    coverageType={coverageTypes.CANCEL_ANYTIME}
                  >
                    <InfoDetail
                      title='Coverage Period'
                      subtitle={`${formatDateToHumanDate(
                        cancelAnytime?.startDate
                      )} - ${formatDateToHumanDate(cancelAnytime?.endDate)}`}
                    >
                      {!cancelAnytimeEligible && (
                        <Tag colorScheme='red' className='py-1 w-fit'>
                          <Icon as={FaExclamationCircle} className='mr-2' />
                          Your coverage is not currently active. You are not
                          eligible to cancel your lease at this time using this
                          coverage.
                        </Tag>
                      )}
                      <Text>
                        Your coverage period starts on the date you purchase
                        your plan and ends on the date your lease starts. You
                        are eligible to cancel your lease at any time during
                        this period and receive benefits.
                      </Text>
                    </InfoDetail>
                    {program?.deductible ? (
                      <InfoDetail
                        title='Deductible'
                        subtitle={FormatAsCurrency(program?.deductible, 'USD')}
                      >
                        <Text>
                          The deductible is the amount you must pay out of
                          pocket before your coverage begins. For example, if
                          your deductible is $500, you must pay $500 out of
                          pocket before your coverage begins.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {concessions > 0 ? (
                      <InfoDetail
                        title='Non-Covered Expenses'
                        subtitle={FormatAsCurrency(concessions, 'USD')}
                      >
                        <Text>
                          ReLease does not cover the{' '}
                          {FormatAsCurrency(concessions, 'USD')} in concessions
                          you received from your landlord as part of this lease
                          agreement.
                        </Text>

                        <Text>
                          ReLease does not cover any concessions or discounts
                          you receive from your landlord. Any non-covered
                          expenses will need to be paid out of pocket prior to
                          receiving benefits. ReLease does not cover or change
                          any terms related to cleaning fees, pet fees, or other
                          fees that are not part of your lease agreement.
                        </Text>
                      </InfoDetail>
                    ) : null}
                  </PostPurchaseCoverageCard>
                ) : null}
                {hasLeaveAnytime && (
                  <PostPurchaseCoverageCard
                    coverageType={coverageTypes.LEAVE_ANYTIME}
                  >
                    <InfoDetail
                      title='Coverage Period'
                      subtitle={`${formatDateToHumanDate(
                        leaveAnytime?.startDate
                      )} - ${formatDateToHumanDate(leaveAnytime?.endDate)}`}
                    >
                      {!leaveAnytimeEligible && (
                        <Tag colorScheme='red' className='py-1 w-fit'>
                          <Icon as={FaExclamationCircle} className='mr-2' />
                          Your coverage is not currently active. You are not
                          eligible to leave your lease at this time using this
                          coverage.
                        </Tag>
                      )}
                      {hasLimitedCoverage ? (
                        <Tag colorScheme='yellow' className='py-1 w-fit'>
                          <Icon as={FaExclamationCircle} className='mr-2' />
                          Your coverage is limited and doesn’t extend through your entire lease agreement. Please contact us for more information.
                        </Tag>
                      ) : null}
                      <Text>
                        Your coverage period starts on the date you purchase
                        your plan and ends on the date your lease starts. You
                        are eligible to leave your lease at any time during this
                        period and receive benefits.
                      </Text>
                    </InfoDetail>
                    {program?.deductible ? (
                      <InfoDetail
                        title='Deductible'
                        subtitle={FormatAsCurrency(program?.deductible, 'USD')}
                      >
                        <Text>
                          The deductible is the amount you must pay out of
                          pocket before your coverage begins. For example, if
                          your deductible is $500, you must pay $500 out of
                          pocket before your coverage begins.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {concessions > 0 ? (
                      <InfoDetail
                        title='Non-Covered Expenses'
                        subtitle={FormatAsCurrency(concessions, 'USD')}
                      >
                        <Text>
                          ReLease does not cover the{' '}
                          {FormatAsCurrency(concessions, 'USD')} in concessions
                          you received from your landlord as part of this lease
                          agreement.
                        </Text>

                        <Text>
                          ReLease does not cover any concessions or discounts
                          you receive from your landlord. Any non-covered
                          expenses will need to be paid out of pocket prior to
                          receiving benefits. ReLease does not cover or change
                          any terms related to cleaning fees, pet fees, or other
                          fees that are not part of your lease agreement.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {program?.requiredStay ? (
                      <InfoDetail
                        title='Required Stay'
                        subtitle={`${program?.requiredStay} months`}
                      >
                        <Text>
                          You must stay in your lease for a minimum of{' '}
                          {program?.requiredStay} months before you are eligible
                          to leave your lease using this coverage. If you leave
                          before this time, you will not be eligible to receive
                          benefits.
                        </Text>
                      </InfoDetail>
                    ) : null}
                  </PostPurchaseCoverageCard>
                )}
              </Flex>
            ) : (
              <EmptyPage
                title='No Coverages'
                subtitle='Your plan does not include any coverages.'
              />
            )}
          </>
        )}

        {/* Contact Us Card */}
        <ContactCard title='Contact Us'>
          Questions about your plan or with claims? Contact us by email, phone,
          or text and we can help you answer any questions you may have about
          purchasing a plan, making claims, and more.
        </ContactCard>
      </Flex>
    </DashboardWrapperLayout>
  );
};

export default PlanDetails;
