import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useEmailLoginRequestMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import AuthMainLayout from 'layouts/auth-main';
import Button from 'common/components/button';
import FormError from 'common/components/form-error';
import WrapInputLabel from 'common/components/wrap-input-label';
import { HiOutlineMail } from 'react-icons/hi';
import { ApplyFormField } from 'common/layouts/apply-form';
import Input from 'common/components/input';
import AuthLabel from 'common/components/auth-label';

const EmailLoginRequest = () => {
  const [emailLoginRequest, { isLoading }] = useEmailLoginRequestMutation();
  const navigate = useNavigate();

  return (
    <AuthMainLayout>
      <div className='px-6 md:px-8 flex flex-col gap-12'>
        <div className='flex gap-3 mobile:gap-0 items-center'>
          <AuthLabel title='Log In' />
          <Link to='/signup' linkId='signupButton'>
            <AuthLabel title='Create Account' isActive={false} />
          </Link>
        </div>
        <div className='flex flex-col items-stretch w-full gap-9'>
          <h1 className='text-4xl font-display tracking-tight'>
            Log Into Your ReLease Account Password-Free
          </h1>
          <p className='text-gray-500'>
            Enter the email you used to sign up, and we'll send you a special
            link to log in.
          </p>
        </div>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Email is required'),
          })}
          onSubmit={async (values, { resetForm, setErrors }) => {
            try {
              await emailLoginRequest(values).unwrap();
              toast.success('An email should be arriving shortly.');
              resetForm();
            } catch (error) {
              // Handle specific error cases, if needed
              if (error?.data?.msg) {
                toast.error(error.data.msg);
                setErrors({ email: error.data.msg }); // Set Formik error
              } else {
                toast.error('Failed to send email.');
              }
            }
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
              <ApplyFormField>
                <WrapInputLabel isRequired>Email Address:</WrapInputLabel>
                <Field
                  as={Input}
                  name='email'
                  type='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  placeholderText='example@gmail.com'
                  Icon={HiOutlineMail}
                />
                <FormError name='email' />
              </ApplyFormField>

              <div className='flex flex-col gap-3'>
                <Button
                  type='submit'
                  disabled={!isValid || isLoading}
                  loading={isLoading}
                  title='Request Email Login Link'
                />
                <Button
                  theme='secondary'
                  title='Log In With Your Password'
                  onClick={() => navigate('/login-with-password')}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </AuthMainLayout>
  );
};

export default EmailLoginRequest;
