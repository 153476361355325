import { Flex, Icon, Skeleton, Tag, Text } from '@chakra-ui/react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  clearPlansDraft,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { coverageTypes } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import PlanInfoCard from 'components/plans/plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import ApplyButtons from 'layouts/apply-buttons';
import {
  PostPurchaseCoverageCard
} from 'components/coverages/coverage-card';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { InfoDetail } from 'components/shared/member-info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isWithinDateRange } from 'common/utils/dateRange';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import { FaExclamationCircle } from 'react-icons/fa';
import { now } from 'moment';

const PlanInReview = ({ isGettingPlan }) => {
  const { trackPageLoad, trackLink } = useUserTracker();
  useEffect(() => {
    trackPageLoad('PlanInReview');
  }, []);

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // DATA INITIALIZATION
  const cancelAnytime = singlePlan?.coverage?.cancelAnytime;
  const leaveAnytime = singlePlan?.coverage?.leaveAnytime;

  const hasCancelAnytime = cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    leaveAnytime?.payment?.onetime?.stripe?.id ||
    leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  const hasLimitedCoverage = useMemo(
    () => leaveAnytime?.endDate < singlePlan?.lease?.endDate,
    [leaveAnytime, singlePlan]
  );

  const program = singlePlan?.property?.owner?.program?.deductible;

  const concessions = singlePlan?.lease?.concessions?.repayable
    ? (singlePlan?.lease?.concessions?.months ?? 0) *
      (singlePlan?.lease?.monthlyRent ?? 0) *
      (singlePlan?.lease?.concessions?.discount ?? 0)
    : 0;

  return (
    <div className='flex flex-col w-full h-full'>
      <ApplyTitle
        title='Review in Progress'
        subtitle='We’re reviewing your details and will update you shortly.'
        buttons={
          <ApplyButtons
            isValid={true}
            handleSubmit={() => {
              navigate('/dashboard/plans');
              dispatch(clearPlansDraft());
            }}
            onNextClick={() => {
              navigate('/dashboard/plans');
              dispatch(clearPlansDraft());
            }}
            disableBack
            nextTitle='Home'
          />
        }
      />

      {/* main content */}
      <ApplyContent>
        {isGettingSinglePlan || isGettingPlan ? (
          <Flex className='flex-col gap-12'>
            <Skeleton className='rounded-md h-[10dvh] w-full' />
            <Flex className='w-full gap-12'>
              <Skeleton className='rounded-md h-[40dvh] w-full' />
              <Skeleton className='rounded-md h-[40dvh] w-full' />
            </Flex>
            <Skeleton className='rounded-md h-[40dvh] w-full' />
            <Skeleton className='rounded-md h-[40dvh] w-full' />
          </Flex>
        ) : isEmpty(singlePlan) ? (
          <Flex className='border-gray-200 rounded-md w-full h-[60dvh] flex items-center justify-center'>
            <Text className='text-lg font-medium'>
              No plan found. Please go back and select a plan.
            </Text>
          </Flex>
        ) : (
          <>
            {/* plan card and info */}
            <Flex className='flex-col gap-6'>
              <h2 className='text-lg font-medium'>Your Plan Details</h2>

              {/* plan info card */}
              <Flex className='flex-col gap-4 w-full'>
                <PlanInfoCard plan={singlePlan} member={userDetails} />
              </Flex>

              <PaymentSummaryCard plan={singlePlan} complete={true} />
            </Flex>

            {/* plan coverages section */}
            {hasCoverage ? (
              <Flex className='flex-col gap-6'>
                {/* coverages heading */}
                <Flex className='flex-col gap-1 w-full items-left'>
                  <h2 className='text-lg font-medium'>Plan Coverages</h2>
                  <Text className='text-base text-gray-500'>
                    Your plan includes the following coverages, limits,
                    deductibles, and exclusions.
                  </Text>
                </Flex>

                {/* coverages */}
                {hasCancelAnytime && (
                  <PostPurchaseCoverageCard
                    coverageType={coverageTypes.CANCEL_ANYTIME}
                  >
                    <InfoDetail
                      title='Coverage Period'
                      subtitle={`${formatDateToHumanDate(
                        cancelAnytime?.startDate
                      )} - ${formatDateToHumanDate(cancelAnytime?.endDate)}`}
                    >
                      <Text>
                        Your coverage period starts on the date you purchase
                        your plan and ends on the date your lease starts. You
                        are eligible to cancel your lease at any time during
                        this period and receive benefits.
                      </Text>
                    </InfoDetail>
                    {program?.deductible ? (
                      <InfoDetail
                        title='Deductible'
                        subtitle={FormatAsCurrency(program?.deductible, 'USD')}
                      >
                        <Text>
                          The deductible is the amount you must pay out of
                          pocket before your coverage begins. For example, if
                          your deductible is $500, you must pay $500 out of
                          pocket before your coverage begins.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {concessions > 0 ? (
                      <InfoDetail
                        title='Non-Covered Expenses'
                        subtitle={FormatAsCurrency(concessions, 'USD')}
                      >
                        <Text>
                          ReLease does not cover the{' '}
                          {FormatAsCurrency(concessions, 'USD')} in concessions
                          you received from your landlord as part of this lease
                          agreement.
                        </Text>

                        <Text>
                          ReLease does not cover any concessions or discounts
                          you receive from your landlord. Any non-covered
                          expenses will need to be paid out of pocket prior to
                          receiving benefits. ReLease does not cover or change
                          any terms related to cleaning fees, pet fees, or other
                          fees that are not part of your lease agreement.
                        </Text>
                      </InfoDetail>
                    ) : null}
                  </PostPurchaseCoverageCard>
                )}
                {hasLeaveAnytime && (
                  <PostPurchaseCoverageCard
                    coverageType={coverageTypes.LEAVE_ANYTIME}
                  >
                    <InfoDetail
                      title='Coverage Period'
                      subtitle={`${formatDateToHumanDate(
                        leaveAnytime?.startDate
                      )} - ${formatDateToHumanDate(leaveAnytime?.endDate)}`}
                    >
                      {hasLimitedCoverage ? (
                        <Tag colorScheme='yellow' className='py-1 w-fit'>
                          <Icon as={FaExclamationCircle} className='mr-2' />
                          Your coverage is limited and doesn’t extend through your entire lease agreement. Please contact us for more information.
                        </Tag>
                      ) : null}
                      <Text>
                        Your coverage period starts on the date you purchase
                        your plan and ends on the date your lease starts. You
                        are eligible to leave your lease at any time during this
                        period and receive benefits.
                      </Text>
                    </InfoDetail>
                    {program?.deductible ? (
                      <InfoDetail
                        title='Deductible'
                        subtitle={FormatAsCurrency(program?.deductible, 'USD')}
                      >
                        <Text>
                          The deductible is the amount you must pay out of
                          pocket before your coverage begins. For example, if
                          your deductible is $500, you must pay $500 out of
                          pocket before your coverage begins.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {concessions > 0 ? (
                      <InfoDetail
                        title='Non-Covered Expenses'
                        subtitle={FormatAsCurrency(concessions, 'USD')}
                      >
                        <Text>
                          ReLease does not cover the{' '}
                          {FormatAsCurrency(concessions, 'USD')} in concessions
                          you received from your landlord as part of this lease
                          agreement.
                        </Text>

                        <Text>
                          ReLease does not cover any concessions or discounts
                          you receive from your landlord. Any non-covered
                          expenses will need to be paid out of pocket prior to
                          receiving benefits. ReLease does not cover or change
                          any terms related to cleaning fees, pet fees, or other
                          fees that are not part of your lease agreement.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {program?.requiredStay ? (
                      <InfoDetail
                        title='Required Stay'
                        subtitle={`${program?.requiredStay} months`}
                      >
                        <Text>
                          You must stay in your lease for a minimum of{' '}
                          {program?.requiredStay} months before you are eligible
                          to leave your lease using this coverage. If you leave
                          before this time, you will not be eligible to receive
                          benefits.
                        </Text>
                      </InfoDetail>
                    ) : null}
                  </PostPurchaseCoverageCard>
                )}
              </Flex>
            ) : null}
          </>
        )}
      </ApplyContent>
    </div>
  );
};

export default PlanInReview;
