import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEmailLoginExchangeMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { setCredentials } from 'redux/features/auth/authSlice';
import LoadingPageLayout from 'common/layouts/loading-page';
import ErrorPageLayout from 'common/layouts/error-page';

const EmailLoginExchange = () => {
  const [emailLoginExchange, { isLoading, isSuccess, isError, error }] =
    useEmailLoginExchangeMutation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const exchangeToken = async () => {
        try {
          const { data } = await emailLoginExchange(token).unwrap();
          toast.success('Login successful!');
          dispatch(
            setCredentials({
              isLoggedIn: true,
              token: data?.token,
              user: data?.user,
            })
          );
          navigate('/dashboard/home');
        } catch (err) {
          toast.error(err?.data?.msg || 'Login failed.');
          navigate('/login'); // Redirect to login on failure
        }
      };

      exchangeToken();
    } else {
      // No token, redirect to login
      navigate('/login');
      toast.error('No token provided.');
    }
  }, [token, emailLoginExchange, dispatch, navigate]);

  if (isLoading) {
    return <LoadingPageLayout />;
  }

  if (isError) {
    toast.error(error?.data?.msg || 'Error while logging in.');
    return (
      <ErrorPageLayout />
    );
  }

  // Keep loading page until success or error occurs and redirects
  return <LoadingPageLayout />;
};

export default EmailLoginExchange;
