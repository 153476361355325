import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { POLLING_INTERVAL, claimStatusEnum } from 'common/utils/enums';
import ClaimsTable from 'components/claims/claims-table';
import EmptyClaims from 'components/empty-pages/empty-claims';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import { useGetAllClaimsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import { Flex } from '@chakra-ui/react';
import ContactCard from 'components/shared/contact-card';
import CTA_Buttons from 'common/components/cta-buttons';

const Claims = () => {
  // DATA INITIALIZATION
  const pageSize = 5;
  const statuses = [
    claimStatusEnum.SUBMITTED,
    claimStatusEnum.IN_REVIEW,
    claimStatusEnum.RESOLVED,
    claimStatusEnum.APPROVED,
    claimStatusEnum.CONFIRMED,    
    claimStatusEnum.COMPLETED,
  ];

  // STATES
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [currentDraftPage, setCurrentDraftPage] = useState(1);
  const [pollingInterval, setPollingInterval] = useState(
    () => POLLING_INTERVAL
  );
  const [sortVal, setSort] = useState('-statusChanges.updatedAt');

  // HELPERS
  const calculateMinMaxPageLimit = (currentPage, pageSize) => {
    const minPageLimit = (currentPage - 1) * pageSize;
    const maxPageLimit = currentPage * pageSize - 1;
    return [minPageLimit, maxPageLimit];
  }

  // HOOKS
  const { data: claimsData, isLoading: isGetAllClaimsLoading } =
    useGetAllClaimsQuery({
      pollingInterval: pollingInterval,
      sort: sortVal,
      status: statuses,
      limit: pageSize,
    });

  const [activeMinPageLimit, activeMaxPageLimit] = useMemo(() => {
    return calculateMinMaxPageLimit(currentActivePage, pageSize);
  }, [currentActivePage]);

  const [draftMinPageLimit, draftMaxPageLimit] = useMemo(() => {
    return calculateMinMaxPageLimit(currentDraftPage, pageSize);
  }, [currentDraftPage]);

  const navigate = useNavigate();

  // Filter claimsData down to only the claims that are in the statuses array, and have a statusChanges array that is not empty
  // Then sort by the last item in statusChanges
  const filteredClaimsData = useMemo(() => {
    if (isEmpty(claimsData)) return [];
    return claimsData
      .filter(
        (claim) =>
          statuses.includes(claim.status)
      )
      .sort((a, b) => {
        const aLast = a.statusChanges[a.statusChanges.length - 1]?.updatedAt;
        const bLast = b.statusChanges[b.statusChanges.length - 1]?.updatedAt;
        
        if (!aLast && !bLast) return 0;
        if (!aLast) return 1;
        if (!bLast) return -1;
        
        return new Date(bLast) - new Date(aLast);
      });
  }, [claimsData]);

  const draftClaimsData = useMemo(() => {
    if (isEmpty(claimsData)) return [];
    return claimsData
      .filter(
        (claim) =>
          claim.status === claimStatusEnum.DRAFT
      )
  }, [claimsData]);

  // USE EFFECT
  useEffect(() => {
    if (isEmpty(claimsData)) {
      setPollingInterval(0);
    } else {
      setPollingInterval(POLLING_INTERVAL);
    }
  }, [claimsData]);

  // Logging
  // useEffect(() => {
  //   console.log('claimsData:', claimsData);
  //   console.log('filteredClaimsData:', filteredClaimsData);
  // }, [claimsData, filteredClaimsData]);

  const ctaButtons = (
    <CTA_Buttons
      onPrimaryClick={() => navigate('/dashboard/claims/submit')}
      primaryTitle='Submit a Claim'
      onSecondaryClick={() => window.open('mailto:members@releaserent.com')}
      secondaryTitle='Get Help from Support'
    />
  );

  return (
    <DashboardWrapperLayout title={'Claims'} buttons={ctaButtons}>
      {/* Main Container */}
      <div className='flex flex-col w-full gap-12'>
        {/* table */}
        {isGetAllClaimsLoading ? (
          <EmptyClaims isLoading={isGetAllClaimsLoading} />
        ) : isEmpty(filteredClaimsData) ? (
          <EmptyClaims />
        ) : (
          <>
            <Flex className='flex-col gap-6'>
              {/* plan heading and status */}
              <h2 className='text-lg font-medium'>Your Submitted Claims</h2>

              <TablePaginateWrapper
                setCurrentPage={setCurrentActivePage}
                currentPage={currentActivePage}
                totalCount={filteredClaimsData?.length}
              >
                <ClaimsTable
                  claims={filteredClaimsData?.slice(activeMinPageLimit, activeMaxPageLimit)}
                  statuses={statuses}
                />
              </TablePaginateWrapper>
            </Flex>

            <Flex className='flex-col gap-6'>
              {/* plan heading and status */}
              <h2 className='text-lg font-medium'>Your Draft Claims</h2>

              <TablePaginateWrapper
                setCurrentPage={setCurrentDraftPage}
                currentPage={currentDraftPage}
                totalCount={draftClaimsData?.length}
              >
                <ClaimsTable
                  claims={draftClaimsData?.slice(draftMinPageLimit, draftMaxPageLimit)}
                  statuses={statuses}
                />
              </TablePaginateWrapper>
            </Flex>
          </>
        )}

        {/* help */}
        <ContactCard title='Need Help With a Claim?'>
          Contact us by email, phone, or text and we can help you answer any
          questions you may have about your claim.
        </ContactCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default Claims;
