import { Icon } from '@chakra-ui/react';
import Button from 'common/components/button';
import ModalBgLayout from 'common/layouts/modal-bg';
import ContactCard from 'components/shared/contact-card';
import { useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreatePlanMutation, useFindPropertiesQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { setPlanStep, savePlansValues } from 'redux/features/plans/plansSlice';
import { extractErrorMsg } from 'utils/errors';

const ApplyChoice = ({ onClick, enabled = true }) => {
  let title = enabled
    ? 'Yes, my building is ReLease-enabled'
    : "No, my building isn't ReLease-enabled";

  let subtitle = enabled
    ? 'I heard about ReLease from my building or community manager'
    : 'I found ReLease on my own and want to apply for a plan';

  return (
    <div
      className={`flex flex-col gap-4 border border-gray-200 rounded-md p-3 hover:bg-gray-50 transition-all`}
      onClick={onClick}
    >
      <h2 className='text-base font-medium' >{title}</h2>
      <p className='text-sm text-gray-500'>
        {subtitle}
      </p>
      <Button className='mt-3' title='Apply' theme={enabled ? 'primary' : 'secondary'} />
    </div>
  );
};

export const PlanApplyModal = ({
  trueClick,
  falseClick,
  onClose,
  className,
}) => {
  return (
    <ModalBgLayout>
      <div className={`flex flex-col gap-3 relative mobile:pt-2 ${className}`}>
        <Button
          title='Cancel'
          hideTitle={true}
          onClick={onClose}
          theme='tertiary'
          wFull={false}
          Icon={FaXmark}
          className='absolute -top-3 -right-3 mobile:-top-1'
        />
        <div className='flex justify-between items-center gap-3'>
          <h2 className='text-lg font-medium'>Apply for a New Plan</h2>
        </div>
        <p className='text-base'>
          Are you applying for a plan at a ReLease-enabled building?
        </p>
        <p className='text-sm text-gray-500'>
          Not sure if your building is ReLease-enabled? Check with your property
          manager or landlord.
        </p>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-3'>
          <ApplyChoice enabled={true} onClick={trueClick} />
          <ApplyChoice enabled={false} onClick={falseClick} />
        </div>
        <h2 className='text-base font-medium mt-6'>Need help with your plan?</h2>
        <ContactCard small />
      </div>
    </ModalBgLayout>
  );
};

export const PlanApplyButton = ({ wFull = false, theme = 'primary' }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { data: propertyData } = useFindPropertiesQuery({
    sort: '-createdAt',
    name: 'Property Not Listed',
  })

  const [
    createAPlan,
    { isLoading: createPlanLoading, isSuccess: createPlanSuccess },
  ] = useCreatePlanMutation();

  const dispatch = useDispatch();

  return (
    <>
      <Button
        title='Apply for a New Plan'
        onClick={() => setShowModal(true)}
        wFull={wFull}
        theme={theme}
      />
      {showModal && 
        <PlanApplyModal 
          onClose={() => setShowModal(false)}
          trueClick={() => navigate('/dashboard/plans/apply')}
          falseClick={() => {
            if (propertyData?.length > 0) {
              createAPlan({ property: propertyData[0].id })
                .then((data) => {
                  dispatch(savePlansValues(data))
                  dispatch(setPlanStep(1))                
                  navigate('/dashboard/plans/apply')
                })
                .catch((error) => {
                  const errMsg = extractErrorMsg(error, 'Failed to start plan application')
                  toast.error(errMsg)
                }
              )
            } else {
              navigate('/dashboard/plans/apply')
            }            
          }}
        />
      }
    </>
  );
};
