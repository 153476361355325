import EmptyPage from 'common/components/empty-page';
import { PlanApplyButton } from 'components/plan-apply/plan-apply-modal';
import { useNavigate } from 'react-router-dom';

const EmptyPlans = ({ isLoading }) => {
  const navigate = useNavigate();
  return (
    <EmptyPage
      title={isLoading ? 'Loading Your Plans' : 'Get Started by Applying for a New Plan'}
      subtitle={isLoading ? 'Please wait while we load your plans.' : "You don't have any plans yet. Once you apply for a plan, you'll be able to view and manage them here."}
      // buttonText='Apply for a New Plan'
      // onClick={() => navigate('/dashboard/plans/apply')}
      FullButton={<PlanApplyButton />}
      isLoading={isLoading}
    />
  );
};

export default EmptyPlans;
