import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex
} from '@chakra-ui/react';
import { planPaymentMode } from 'utils/enums';
import FormatAsCurrency from 'common/utils/formatAsCurrency';

function getLineItems(
  plan,
  selectedCancelAnytime,
  selectedLeaveAnytimeOnetime,
  selectedLeaveAnytimeSubscription
) {
  // Create a list of line items for one-time and monthly payments
  let onetime = {
    lineItems: [],
    total: 0,
  };
  let monthly = {
    lineItems: [],
    total: 0,
  };

  // Set up consts for coverages
  const cancelAnytime = plan?.coverage?.cancelAnytime;
  const leaveAnytime = plan?.coverage?.leaveAnytime;

  // Check if coverages are present
  const hasCancelAnytime =
    cancelAnytime?.payment?.stripe?.id || selectedCancelAnytime;
  const hasLeaveAnytimeOnetime =
    leaveAnytime?.payment?.onetime?.stripe?.id || selectedLeaveAnytimeOnetime;
  const hasLeaveAnytimeSubscription =
    leaveAnytime?.payment?.subscription?.stripe?.id ||
    selectedLeaveAnytimeSubscription;
  const hasCoverage =
    hasCancelAnytime || hasLeaveAnytimeOnetime || hasLeaveAnytimeSubscription;

  // Add cancel anytime coverage to one-time line items if it's selected
  if (hasCancelAnytime) {
    onetime.lineItems.push({
      name: 'Cancel Anytime Coverage',
      description: 'One-time upfront payment',
      amount: cancelAnytime?.payment?.amount,
    });
  }

  // Add leave anytime coverage to one-time line items if it's selected
  if (hasLeaveAnytimeOnetime) {
    onetime.lineItems.push({
      name: 'Leave Anytime Coverage',
      description: 'One-time upfront payment',
      amount: leaveAnytime?.payment?.onetime?.amount,
    });
  } else if (hasLeaveAnytimeSubscription) {
    onetime.lineItems.push({
      name: 'Leave Anytime Coverage',
      description: 'First monthly payment',
      amount: leaveAnytime?.payment?.subscription?.amount,
    });
    monthly.lineItems.push({
      name: 'Leave Anytime Coverage',
      description: 'Monthly recurring payments',
      amount: leaveAnytime?.payment?.subscription?.amount,
    });
  }

  // Support for old plans that don't have coverages set up at all
  // This is a temporary fix until all plans have coverages set up
  // Remove this block once all plans have coverages set up
  // Old one-time payments
  if (!hasCoverage && plan?.payment?.choice === planPaymentMode.ONE_TIME) {
    onetime.lineItems.push({
      name: 'ReLease Coverage',
      description: 'One-time upfront payment',
      amount: plan?.payment?.oneTimePayment,
    });
  } else if (
    !hasCoverage &&
    plan?.payment?.choice === planPaymentMode.SUBSCRIPTION
  ) {
    // Old monthly payments
    onetime.lineItems.push({
      name: 'ReLease Coverage',
      description: 'First monthly payment',
      amount: plan?.payment?.monthlyPayment,
    });
    monthly.lineItems.push({
      name: 'ReLease Coverage',
      description: 'Monthly recurring payments',
      amount: plan?.payment?.monthlyPayment,
    });
  }

  // Calculate the total for one-time line items
  onetime.total = onetime.lineItems.reduce(
    (total, item) => total + item.amount,
    0
  );

  // Calculate the total for monthly line items
  monthly.total = monthly.lineItems.reduce(
    (total, item) => total + item.amount,
    0
  );

  console.log('onetime', onetime);
  console.log('monthly', monthly);

  return { onetime, monthly };
}

const PaymentSummary = ({
  onetime,
  monthly,
  complete,
  gridLayout = 'grid-cols-[1fr_auto_100px] sm:grid-cols-[200px_1fr_100px]',
}) => {
  return (
    <>
      {/* Due now payment information */}
      {onetime.total > 0 && (
        <Flex className={`flex-col gap-1 text-base`}>
          {/* due now text */}
          <Text className='font-medium text-base'>
            {complete ? 'Paid Upfront' : 'Due Today'}
          </Text>
          <Text className='mb-4 text-gray-500'>
            {complete
              ? 'This was the amount you were charged when you completed your payment.'
              : 'This is the amount you will be charged today when you complete your payment. If your application is not approved, any payment made will be refunded to you.'}
          </Text>

          <Flex className={`flex-col gap-1 text-base`}>
            {onetime.lineItems.map((item, index) => (
              <div key={index} className={`grid gap-2 w-full justify-between ${gridLayout}`}>
                <Text className='font-normal'>{item.name}</Text>
                <Text className='hidden sm:block text-gray-500'>
                  {item.description}
                </Text>
                <Text className='text-right text-gray-500'>
                  {FormatAsCurrency(item.amount, 'USD')}
                </Text>
              </div>
            ))}

            {/* tax */}
            <div className={`grid gap-2 w-full justify-between ${gridLayout} pt-2 mt-2 border-t border-gray-200`} >
              <Text className='font-normal'>Applicable Taxes</Text>
              <Text></Text>
              <Text className='text-right text-gray-500'>
                {FormatAsCurrency(0, 'USD')}
              </Text>
            </div>

            {/* Total */}
            <div className={`grid gap-2 w-full justify-between ${gridLayout} font-medium`}>            
              <Text>Total</Text>
              <Text></Text>
              <Text className='text-right'>
                {FormatAsCurrency(onetime.total, 'USD')}
              </Text>
            </div>
          </Flex>
        </Flex>
      )}

      {/* Due monthly payment  information */}
      {monthly.total > 0 && (
        <Flex className={`flex-col gap-1 text-base`}>
          {/* due now text */}
          <Text className='font-medium text-base'>Due Monthly</Text>
          <Text className='mb-4 text-gray-500'>
            This is the amount you will be charged monthly once your plan is
            active, until your coverage end date. If your application is not
            approved, any pending monthly payments will be cancelled.
          </Text>

          <Flex className={`flex-col gap-1 text-base`}>
            {monthly.lineItems.map((item, index) => (
              <div key={index} className={`grid gap-2 w-full justify-between ${gridLayout}`}>
                <Text className='font-normal'>{item.name}</Text>
                <Text className='hidden sm:block text-gray-500'>
                  {item.description}
                </Text>
                <Text className='text-right text-gray-500'>
                  {FormatAsCurrency(item.amount, 'USD')}
                </Text>
              </div>
            ))}

            {/* tax */}
            <div className={`grid gap-2 w-full justify-between ${gridLayout} pt-2 mt-2 border-t border-gray-200`} >
              <Text className='font-normal'>Applicable Taxes</Text>
              <Text></Text>
              <Text className='text-right text-gray-500'>
                {FormatAsCurrency(0, 'USD')}
              </Text>
            </div>

            {/* Total */}
            <div className={`grid gap-2 w-full justify-between ${gridLayout} font-medium`}>
              <Text>Total</Text>
              <Text></Text>
              <Text className='text-right'>
                {FormatAsCurrency(monthly.total, 'USD')}
              </Text>
            </div>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const PaymentSummaryCard = ({
  plan,
  selectedCancelAnytime,
  selectedLeaveAnytimeOnetime,
  selectedLeaveAnytimeSubscription,
  complete = false,
  paymentMode = true,
  card = true,
}) => {
  const { onetime, monthly } = getLineItems(
    plan,
    selectedCancelAnytime,
    selectedLeaveAnytimeOnetime,
    selectedLeaveAnytimeSubscription
  );

  if (onetime.total === 0 && monthly.total === 0) {
    return null;
  } else {
    return (
      <Flex
        className={`flex-col ${
          card ? 'rounded-md border border-gray-200' : ''
        }`}
      >
        <Accordion
          allowMultiple
          className='flex w-full flex-col rounded-md overflow-hidden'
        >
          <AccordionItem className='border-none'>
            <div
              className={`flex mobile:flex-col gap-4 justify-between items-center mobile:items-start ${
                card ? 'p-6' : 'pb-6'
              }`}
            >
              <div className='flex flex-col gap-1'>
                <h2 className='capitalize text-lg font-medium'>
                  Payment Details
                </h2>
                <Text className='text-base text-gray-500'>
                  Here’s a summary of your payments based on the coverages you
                  selected.
                </Text>
              </div>

              {/* Quick view of monthly/onetime totals */}
              <Flex className='gap-5 divide-x divide-gray-200 items-center'>
                {onetime.total > 0 && (
                  <div className='flex flex-col items-end mobile:items-start'>
                    <div className='text-xl font-medium'>
                      ${onetime.total.toFixed(2)} {plan?.lease?.currency}
                    </div>
                    <div className='text-base text-gray-500'>
                      {complete ? 'Paid Upfront' : 'Due Today'}
                    </div>
                  </div>
                )}
                {monthly.total > 0 && (
                  <div className='flex flex-col items-end mobile:items-start pl-4'>
                    <div className='text-xl font-medium'>
                      ${monthly.total.toFixed(2)} {plan?.lease?.currency}
                    </div>
                    <div className='text-base text-gray-500'>Due Monthly</div>
                  </div>
                )}
              </Flex>
            </div>

            {/* Accordion to expand to show all */}
            <AccordionButton
              className={`py-2 px-6 hover:bg-gray-50 border-gray-200 ${
                card ? 'border-t' : 'border rounded-md'
              }`}
            >
              <div className='w-full text-left text-base font-medium py-1'>
                Show Expanded Payment Details
              </div>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel
              className={`flex flex-col gap-8 p-6 ${
                card ? 'border-t' : 'border rounded-md mt-4'
              }`}
            >
              {/* payment information */}
              <PaymentSummary
                onetime={onetime}
                monthly={monthly}
                complete={complete}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  }
};

export default PaymentSummaryCard;
