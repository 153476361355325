import { Flex, Tag, Text } from '@chakra-ui/react';
import LogoImage from 'common/components/logo-image';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { InfoField } from 'common/components/info-card';
import CopyLink from 'common/components/copy-link';
import { useEffect, useState } from 'react';
import { getDateRangeStatus } from 'common/utils/dateRange';
import { dateRangeStatusEnum, planStatusEnum } from 'common/utils/enums';

const PlanInfoCard = ({
  plan,
  member,
  selectedCancelAnytime = false,
  selectedLeaveAnytime = false,
}) => {
  // DATA INITIALIZATION
  const address = plan?.lease?.address;
  const name = member?.name || `${member?.firstName} ${member?.lastName}`;
  const property = plan?.property?.name;

  // Check eligibility of coverages
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const cancelAnytime = plan.coverage?.cancelAnytime;
  const leaveAnytime = plan?.coverage?.leaveAnytime;

  const hasCancelAnytime = cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    leaveAnytime?.payment?.onetime?.stripe?.id ||
    leaveAnytime?.payment?.subscription?.stripe?.id;

  const hasCoverage =
    hasCancelAnytime ||
    hasLeaveAnytime ||
    selectedCancelAnytime ||
    selectedLeaveAnytime;

  const cancelAnytimeDetails =
    hasCancelAnytime || selectedCancelAnytime
      ? {
          title: 'Cancel Anytime',
          coveragePeriod: `${formatDateToHumanDate(
            cancelAnytime?.startDate
          )} - ${formatDateToHumanDate(cancelAnytime?.endDate)}`,
          startDate: cancelAnytime?.startDate,
          endDate: cancelAnytime?.endDate,
          status: getDateRangeStatus(cancelAnytime, now),
        }
      : null;

  const leaveAnytimeDetails =
    hasLeaveAnytime || selectedLeaveAnytime
      ? {
          title: 'Leave Anytime',
          coveragePeriod: `${formatDateToHumanDate(
            leaveAnytime?.startDate
          )} - ${formatDateToHumanDate(leaveAnytime?.endDate)}`,
          startDate: leaveAnytime?.startDate,
          endDate: leaveAnytime?.endDate,
          status: getDateRangeStatus(leaveAnytime, now),
        }
      : null;

  const coverages = [leaveAnytimeDetails, cancelAnytimeDetails].filter(Boolean);
  const showStatuses = [dateRangeStatusEnum.BEFORE, dateRangeStatusEnum.AFTER];

  return (
    <Flex className='p-8 bg-gray-100 border border-gray-200 rounded-md flex-col gap-4 w-full'>
      {/* logo */}
      <Flex className='w-full gap-6 flex-col mb-5 sm:flex-row sm:justify-between sm:items-center'>
        <LogoImage dark={true} size='xs' />
        <Flex className='text-gray-500 uppercase tracking-widest text-sm font-semibold gap-x-4 gap-y-2 flex-col sm:flex-row'>
          <CopyLink text={plan?.id || ''} title={`plan number ${plan?.id}`} />
          {plan?.status}
        </Flex>
      </Flex>

      {/* street address and name */}
      <Flex className='flex-col gap-6 w-full'>
        <Flex className='flex-col gap-2'>
          <Text className='capitalize font-medium tracking-wide'>
            {address?.streetAddress1}
            {address?.streetAddress2 && `, ${address?.streetAddress2}`},{' '}
            {address?.city}
            {address?.state && `, ${address?.state}`}, {address?.country}
            {address?.postalCode && `, ${address?.postalCode}`}
          </Text>
          {property && (
            <Text className='text-gray-500 text-sm font-medium tracking-wide'>
              {property}
            </Text>
          )}
        </Flex>
        <Text className='font-medium text-base'>{name}</Text>
      </Flex>

      {/* Coverages */}
      {hasCoverage && (
        <Flex className='flex-col gap-6 w-full sm:flex-row pt-6 border-t border-gray-200'>
          {coverages?.map((coverage, index) => (
            <Flex key={index} className='flex-col gap-4 w-full'>
              <Text className='capitalize text-sm font-medium'>
                {coverage.title}
              </Text>
              <Flex className='gap-4 flex-col sm:flex-row'>
                <InfoField title='Coverage Period'>
                  {coverage.coveragePeriod}
                  {showStatuses.includes(coverage.status) ? (
                    <Tag
                      className='ml-3'
                      colorScheme={
                        coverage.status === dateRangeStatusEnum.BEFORE
                          ? 'blue'
                          : 'red'
                      }
                      size='sm'
                    >
                      <span className='capitalize'>{coverage.status}</span>
                    </Tag>
                  ) : null}
                </InfoField>
                {/* Not using coverage limit right now */}
                {/* <Flex key={index} className='flex-col w-full'>
                  <Flex className='uppercase tracking-wider text-sm font-bold'>                  
                    <span>Coverage Limit</span>
                  </Flex>
                  <Text className='text-lg uppercase tracking-wider'>
                    {coverage.limit}
                  </Text>
                </Flex> */}
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default PlanInfoCard;
