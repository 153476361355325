import { useNavigate } from 'react-router-dom';
import EmptyPage from 'common/components/empty-page';

const EmptyClaims = ({ isLoading }) => {
  const navigate = useNavigate();
  return (
    <EmptyPage
      title={isLoading ? 'Loading Your Claims' : 'Keep Track of Your Claims Here'}
      subtitle={isLoading ? 'Please wait while we load your claims.' : "You don't have any claims yet. Once you have submitted claims, you'll be able to track their status and manage them here."}
      buttonText='Submit a Claim'
      onClick={() => navigate('/dashboard/claims/submit')}
      isLoading={isLoading}
    />
  );
};

export default EmptyClaims;
