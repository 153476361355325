import WrapInputLabel from 'common/components/wrap-input-label';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import * as Yup from 'yup';
import ApplyButtons from 'layouts/apply-buttons';
import { useUpdateSinglePlanMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import FormError from 'common/components/form-error';
import Input from 'common/components/input';
import { extractErrorMsg } from 'utils/errors';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'common/layouts/apply-form';
import { Flex, Tag, Text } from '@chakra-ui/react';
import RadioGroup from 'components/shared/boolean-radio-input';
import SelectInput from 'common/components/select-input';
import { residencyStatusOptions } from 'utils/selectOptions';
import { isEmpty } from 'common/utils/isEmpty';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { studentStatusOptions } from 'common/utils/selectOptions';
import { studentStatusEnum } from 'common/utils/enums';
import EnumRadioGroup from 'components/shared/enum-radio-input';
import { toDatePickerFormat } from 'common/utils/toDatePickerFormat';

const PlanStepPersonalInfo = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {
  // HOOKS
  const savedPlansValue = useSelector(selectPlansValues);
  console.log(savedPlansValue);

  const dispatch = useDispatch();
  const [
    // eslint-disable-next-line no-unused-vars
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();

  // eslint-disable-next-line no-unused-vars
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // SIDE EFFECTS
  useEffect(() => {
    if (isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isUpdatePlanSuccess]);

  // Create option menu for visa selection
  const createResidencyOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ label, examples, value, ...res }) => ({
        label: (
          <div className='w-full flex gap-2 justify-between'>
            <div className='text-md font-medium'>{label}</div>
            <div className='text-md text-gray-500 hidden gap-2 sm:flex'>
              {examples?.map((example, index) => (
                <Tag
                  key={index}
                  size='sm'
                  colorScheme='gray'
                  className='rounded-full px-3'
                >
                  {example}
                </Tag>
              ))}
            </div>
          </div>
        ),
        value: value,
      })
    );
  };

  // Not sure why we implemented this feature, but it exists
  // const fieldsDisabled = !isEmpty(savedPlansValue) && !isEdit;
  const fieldsDisabled = false;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        jobQuitPlans: savedPlansValue?.jobQuitPlans,
        movePlans: savedPlansValue?.movePlans,
        residencyStatus: savedPlansValue?.residencyStatus || 'citizenPermanent',
        visaExpiration: savedPlansValue?.visaExpiration?.split('T')[0],
        rentalHistory: {
          addressesLived: savedPlansValue?.rentalHistory?.addressesLived - 1,
          terminationHistory:
            savedPlansValue?.rentalHistory?.terminationHistory,
          evictionHistory: savedPlansValue?.rentalHistory?.evictionHistory,
        },
        school: {
          status: savedPlansValue?.school?.status,
          graduationDate: toDatePickerFormat(savedPlansValue?.school?.graduationDate),
          programEndDate: toDatePickerFormat(savedPlansValue?.school?.programEndDate),
        }
      }}
      validationSchema={Yup.object().shape({
        jobQuitPlans: Yup.boolean().nullable().required(),
        movePlans: Yup.boolean().nullable().required(),
        residencyStatus: Yup.string().required(),
        visaExpiration: Yup.date().nullable(),
        // .when('residencyStatus', (residencyStatus, schema) => {
        //   if (residencyStatus !== 'citizenPermanent') {
        //     return schema.required();
        //   }
        //   return schema.nullable();
        // }),
        rentalHistory: Yup.object().shape({
          addressesLived: Yup.number().required(),
          terminationHistory: Yup.boolean().required(),
          evictionHistory: Yup.boolean().required(),
        }),
        school: Yup.object().shape({
          status: Yup.string().required(),
          graduationDate: Yup.date().optional().nullable(),
          programEndDate: Yup.date().optional().nullable(),
        }),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          jobQuitPlans: values.jobQuitPlans,
          movePlans: values.movePlans,
          residencyStatus: values.residencyStatus,
          visaExpiration: values.visaExpiration,
          rentalHistory: {
            addressesLived: values.rentalHistory.addressesLived + 1,
            terminationHistory: values.rentalHistory.terminationHistory,
            evictionHistory: values.rentalHistory.evictionHistory,
          },
          school: {
            status: values.school.status,
            ...(values.school.status === studentStatusEnum.CURRENT_STUDENT && {
              graduationDate: values.school.graduationDate,
              programEndDate: values.school.programEndDate,
            }),
          },
          planId: savedPlansValue.id,
        };

        trackFormSubmission(submissionValues);

        updatePlan(submissionValues)
          .unwrap()
          .then((data) => {
            dispatch(savePlansValues(data || {}));
            onNextClick();
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        isValid,
        dirty: isDirty,
        handleSubmit,
        setFieldTouched,
      }) => {
        return (
          <Form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col w-full h-full'
          >
            <ApplyTitle
              title='Personal Info'
              subtitle='Share some basic details about yourself.'
              buttons={
                <ApplyButtons
                  loading={updatePlanLoading}
                  stepNumber={stepNumber}
                  isValid={
                    isValid &&
                    (isDirty || savedPlansValue?.rentalHistory?.addressesLived)
                  }
                  handleSubmit={isDirty ? handleSubmit : onNextClick}
                  moveOn={fieldsDisabled}
                  onNextClick={onNextClick}
                  onBackClick={onBackClick}
                />
              }
            />

            {/* main content */}
            <ApplyContent>
              {/* container */}
              <ApplyFormContainer>
                {/* Pre-meditated events */}
                <ApplyFormLayout className='pb-6'>
                  <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                    <Text className='font-normal text-base'>
                      Have you already signed or otherwise committed to an offer
                      of employment that may require you to prematurely cancel
                      your lease?
                    </Text>
                    <RadioGroup
                      value={
                        values.jobQuitPlans != null
                          ? values.jobQuitPlans.toString()
                          : ''
                      }
                      onChange={(value) => {
                        const booleanValue = value === 'true';
                        setFieldValue('jobQuitPlans', booleanValue);
                      }}
                      name='jobQuitPlans'
                    />
                    <FormError name='jobQuitPlans' />
                  </Flex>
                  <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                    <Text className='font-normal text-base'>
                      Are you currently aware of or committed to any other plans
                      or decisions that may require you to prematurely cancel
                      your lease?
                    </Text>
                    <RadioGroup
                      value={
                        values.movePlans != null
                          ? values.movePlans.toString()
                          : ''
                      }
                      onChange={(value) => {
                        const booleanValue = value === 'true';
                        setFieldValue('movePlans', booleanValue);
                      }}
                      name='movePlans'
                    />
                    <FormError name='movePlans' />
                  </Flex>
                </ApplyFormLayout>

                {/* Residency Status */}
                <ApplyFormLayout className='py-6'>
                  {/* Select Residency Status */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Residency Status</WrapInputLabel>
                    <SelectInput
                      disabled={fieldsDisabled}
                      placeholderText='Select your residency status'
                      options={createResidencyOptionMenu(
                        residencyStatusOptions
                      )}
                      value={createResidencyOptionMenu(
                        residencyStatusOptions
                      ).find(
                        (status) => status.value === values.residencyStatus
                      )}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setFieldValue(
                            'residencyStatus',
                            selectedOption.value
                          ).then(() => {
                            setFieldTouched('residencyStatus');
                          });
                        } else {
                          // Handle case when select is cleared
                          setFieldValue('residencyStatus', null).then(() => {
                            setFieldTouched('residencyStatus');
                          });
                        }
                      }}
                      onBlur={handleBlur}
                      name='residencyStatus'
                    />
                    <FormError name='residencyStatus' />
                  </ApplyFormField>

                  {/* Visa Expiration Date */}
                  {values.residencyStatus != 'citizenPermanent' && (
                    <ApplyFormField>
                      <WrapInputLabel isRequired>
                        Visa Expiration Date
                      </WrapInputLabel>
                      <Input
                        type='date'
                        name='visaExpiration'
                        value={values.visaExpiration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={fieldsDisabled}
                      />
                      <FormError name='visaExpiration' />
                    </ApplyFormField>
                  )}
                </ApplyFormLayout>

                {/* Rental History */}
                <ApplyFormLayout className='py-6'>
                  <Text className='font-medium text-lg col-span-2'>
                    Information About Your Past 3 Years of Rental History
                  </Text>
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                      How Many Times Have You Moved?
                    </WrapInputLabel>
                    <Input
                      type='number'
                      name='rentalHistory.addressesLived'
                      value={values.rentalHistory.addressesLived}
                      placeholderText='Enter a number'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={fieldsDisabled}
                      suffix='times'
                    />
                    <FormError name='rentalHistory.addressesLived' />
                  </ApplyFormField>
                  <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                    <Text className='text-base'>
                      Have you terminated more than 2 leases in the past 3
                      years?
                    </Text>
                    <RadioGroup
                      value={
                        values.rentalHistory.terminationHistory != null
                          ? values.rentalHistory.terminationHistory.toString()
                          : ''
                      }
                      onChange={(value) => {
                        const booleanValue = value === 'true';
                        setFieldValue(
                          'rentalHistory.terminationHistory',
                          booleanValue
                        );
                      }}
                      name='rentalHistory.terminationHistory'
                    />
                    <FormError name='rentalHistory.terminationHistory' />
                  </Flex>
                  <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                    <Text className='text-base'>
                      Have you been evicted in the past 3 years?
                    </Text>
                    <RadioGroup
                      value={
                        values.rentalHistory.evictionHistory != null
                          ? values.rentalHistory.evictionHistory.toString()
                          : ''
                      }
                      onChange={(value) => {
                        const booleanValue = value === 'true';
                        setFieldValue(
                          'rentalHistory.evictionHistory',
                          booleanValue
                        );
                      }}
                      name='rentalHistory.evictionHistory'
                    />
                    <FormError name='rentalHistory.evictionHistory' />
                  </Flex>
                </ApplyFormLayout>

                {/* School Info */}
                <ApplyFormLayout className='pt-6'>
                  <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                    <Text className='text-base'>
                      Are you currently enrolled in school?
                    </Text>
                    <EnumRadioGroup
                      enumOptions={[
                        { label: 'Yes', value: studentStatusEnum.CURRENT_STUDENT },
                        { label: 'No', value: studentStatusEnum.NOT_STUDENT },
                      ]}
                      value={values.school.status || null}
                      onChange={(value) => {
                        setFieldValue('school.status', value);
                      }}
                      name='school.status'
                    />
                    <FormError name='school.status' />
                  </Flex>
                  {values.school.status === studentStatusEnum.CURRENT_STUDENT ? (
                    <>
                      <ApplyFormField small>
                        <WrapInputLabel isRequired>
                          Expected Graduation Date
                        </WrapInputLabel>
                        <Input
                          type='date'
                          name='school.graduationDate'
                          value={values.school.graduationDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={fieldsDisabled}
                        />
                        <FormError name='school.graduationDate' />
                      </ApplyFormField>
                      <ApplyFormField small>
                        <WrapInputLabel isRequired>
                          Expected Program End Date
                        </WrapInputLabel>
                        <Input
                          type='date'
                          name='school.programEndDate'
                          value={values.school.programEndDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={fieldsDisabled}
                        />
                        <FormError name='school.programEndDate' />
                      </ApplyFormField>
                    </>
                  ) : null}
                </ApplyFormLayout>
              </ApplyFormContainer>

              {/* <Tracker
              values={values}
              errors={errors}
              touched={touched}
              formName='stepThree'
            /> */}
            </ApplyContent>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlanStepPersonalInfo;
