import { Text, Flex, Icon } from '@chakra-ui/react';
import { FaEnvelope, FaPhone } from 'react-icons/fa6';
import CopyLink from 'common/components/copy-link';

const MiniCard = ({ className }) => {
  return(
    <Flex
          className={`text-base flex-col w-full border border-gray-200 rounded-md divide-y divide-gray-200 ${className}`}
        >
          {/* email */}
          <Flex
            className='flex justify-between px-4 py-2 cursor-pointer hover:bg-gray-50 flex-col gap-1 sm:flex-row sm:gap-4 text-base'
          >
            <Flex className='gap-4 items-center'>
              <Icon as={FaEnvelope} boxSize={3} className='text-gray-500' />
              <Text className='font-medium'>Email</Text>
            </Flex>

            <Flex className='text-gray-500 gap-2 items-center ml-7 sm:ml-0'>
              <CopyLink title='contact email' text='members@releaserent.com' />
            </Flex>
          </Flex>

          {/* phone */}
          <Flex
            className='flex justify-between px-4 py-2 cursor-pointer hover:bg-gray-50 flex-col gap-1 sm:flex-row sm:gap-4 text-base'
          >
            <Flex className='gap-4 items-center'>
              <Icon as={FaPhone} boxSize={3} className='text-gray-500' />
              <Text className='font-medium'>Phone or Text</Text>
            </Flex>
            <Flex className='text-gray-500 gap-2 items-center ml-7 sm:ml-0'>
              <CopyLink title='contact phone number' text='(701) 515-9759' />
            </Flex>
          </Flex>
        </Flex>
  )
}

const ContactCard = ({
  title = 'Still Have Questions?',
  children = 'Our team is here to help you understand which plan is the best fit for you. Contact us by email, phone, or text and we can help you answer any questions you may have about purchasing a plan.',
  small = false,
  className,
}) => {
  return (
    <>
      {small ? (
        <MiniCard className={className}/>
      ) : (
        <Flex
          className={`flex flex-col gap-2 p-6 rounded-lg border border-gray-200 ${className}`}
        >
          <h2 className='text-lg font-medium'>{title}</h2>
          <Text className='text-base mb-4 text-gray-500'>{children}</Text>

          <MiniCard />
        </Flex>
      )}
    </>
  );
};

export default ContactCard;
