import { Formik } from 'formik';
import { useRef } from 'react';
import { useSubmitClaimMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import ApplyButtons from 'layouts/apply-buttons';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'common/layouts/apply-form';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Radio,
  RadioGroup,
  Tag,
  Text,
} from '@chakra-ui/react';
import WrapInputLabel from 'common/components/wrap-input-label';
import SelectInput from 'common/components/select-input';
import FormError from 'common/components/form-error';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { extractErrorMsg } from 'utils/errors';
import ContactCard from 'components/shared/contact-card';
import Input from 'common/components/input';
import TextAreaInput from 'common/components/text-area-input';
import { claimPaymentPreferenceEnum, claimTypeEnum } from 'utils/enums';
import { toDatePickerFormat } from 'common/utils/toDatePickerFormat';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { HiCheckBadge } from 'react-icons/hi2';

const Edit = ({ claim }) => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [
    submitClaim,
    { data: submittedClaimData, isLoading: submitClaimLoading },
  ] = useSubmitClaimMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        claimId: claim.id,
        claimType: claim.claimType,
        submission: {
          moveReason: claim?.submission?.moveReason,
          moveOutStart: claim?.submission?.moveOutStart,
          moveOutEnd: claim?.submission?.moveOutEnd,
          paymentPreference: claim?.submission?.paymentPreference,
        },
      }}
      validationSchema={Yup.object().shape({
        claimType: Yup.string().required('Required'),
        submission: Yup.object().shape({
          moveReason: Yup.string().required('Required'),
          paymentPreference: Yup.string().required('Required'),
          moveOutStart: Yup.date().when('claimType', {
            is: (val) => val === claimTypeEnum.leaveAnytime,
            then: Yup.date().required('Required'),
          }),
          moveOutEnd: Yup.date().when('claimType', {
            is: (val) => val === claimTypeEnum.leaveAnytime,
            then: Yup.date().required('Required'),
          }),
        }),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          claimId: claim.id,
          claimType: values.claimType,
          submission: {
            moveReason: values.submission.moveReason,
            moveOutStart: values.submission.moveOutStart,
            moveOutEnd: values.submission.moveOutEnd,
            paymentPreference: values.submission.paymentPreference,
          },
        };

        trackFormSubmission(submissionValues);

        submitClaim(submissionValues)
          .unwrap()
          .then(() => {
            navigate(`/dashboard/claims/${claim.id}`);
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Submit Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        dirty: isDirty,
        setFieldTouched,
      }) => {
        return (
          <div className='flex flex-col w-full h-full'>
            <ApplyTitle
              title='Claim Details'
              subtitle='Please let us know more about your claim details'
              buttons={
                <ApplyButtons
                  loading={submitClaimLoading}
                  isValid={isValid && isDirty}
                  moveOn={false}
                  handleSubmit={handleSubmit}
                  nextTitle='Submit Claim'
                  disableBack
                />
              }
            />

            {/* Main Area */}
            <ApplyContent>
              {/* container */}
              <ApplyFormContainer>
                <ApplyFormLayout>
                  {/* Only show move out window for leave anytime */}
                  {claim.claimType === 'leaveAnytime' && (
                    <>
                      <Flex className='col-span-2 flex-col gap-2'>
                        <Text className='text-lg ont-medium col-span-2'>
                          What is the earliest possible and latest possible date
                          you can move out?
                        </Text>
                        <Text className='col-span-2 text-base text-gray-500'>
                          You are responsible for paying rent until the month
                          immeidately following your move-out date. For example,
                          if your move-out date is on Oct 25, you are
                          responsible for paying rent until Nov 1.
                        </Text>
                      </Flex>

                      <ApplyFormField small>
                        <WrapInputLabel isRequired>
                          Earliest Date
                        </WrapInputLabel>
                        <Input
                          placeholderText='Select date'
                          type='date'
                          value={values.submission.moveOutStart}
                          onChange={(event) => {
                            handleChange(event);
                            setFieldTouched('submission.moveOutStart');
                          }}
                          onBlur={handleBlur}
                          name='submission.moveOutStart'
                          min={toDatePickerFormat(
                            new Date(
                              new Date().setDate(new Date().getDate() + 7)
                            )
                          )}
                        />
                        <FormError name='submission.moveOutStart' />
                      </ApplyFormField>

                      {/* Lease End Date */}
                      <ApplyFormField small>
                        <WrapInputLabel isRequired>Latest Date</WrapInputLabel>
                        <Input
                          placeholderText='Select date'
                          type='date'
                          value={values.submission.moveOutEnd}
                          onChange={(event) => {
                            setFieldTouched('moveOutEnd');
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          name='submission.moveOutEnd'
                          min={toDatePickerFormat(
                            new Date(
                              new Date().setDate(new Date().getDate() + 7)
                            )
                          )}
                          max={toDatePickerFormat(
                            claim?.planId?.lease?.endDate
                          )}
                        />
                        <FormError name='submission.moveOutEnd' />
                      </ApplyFormField>
                    </>
                  )}

                  <ApplyFormField>
                    <WrapInputLabel isRequired>Reason for claim</WrapInputLabel>
                    <TextAreaInput
                      placeholderText={
                        claim.claimType === 'leaveAnytime'
                          ? 'Please let us know why you are moving out early'
                          : 'Please let us know why you are cancelling your upcoming lease'
                      }
                      value={values.submission.moveReason}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldTouched('submission.moveReason');
                      }}
                      onBlur={handleBlur}
                      name='submission.moveReason'
                    />
                    <FormError name='submission.moveReason' />
                  </ApplyFormField>

                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                      Payment Preference
                    </WrapInputLabel>

                    <RadioGroup
                      className='flex flex-col gap-4'
                      onChange={(option) => {
                        if (option) {
                          setFieldValue(
                            'submission.paymentPreference',
                            option
                          ).then(() => {
                            setFieldTouched('submission.paymentPreference');
                          });
                        } else {
                          // Handle case when select is cleared
                          setFieldValue(
                            'submission.paymentPreference',
                            null
                          ).then(() => {
                            setFieldTouched('submission.paymentPreference');
                          });
                        }
                      }}
                    >
                      <Flex className='rounded-md border border-gray-200 divide-y divide-gray-200 p-4'>
                        <Radio
                          value={claimPaymentPreferenceEnum.DIRECT}
                          size='lg'
                        >
                          <Text className='ml-2 text-base'>
                            Direct payment to my landlord
                            <Tag
                              className='inline-flex gap-2 ml-4 text-sm pl-2 pr-3 rounded-full'
                              colorScheme='green'
                            >
                              <Icon as={HiCheckBadge} boxSize={3} />
                              Recommended
                            </Tag>
                          </Text>
                        </Radio>
                      </Flex>

                      {claim?.planId?.lease?.terminationPolicy?.buyout && (
                        <Flex className='rounded-md border border-gray-200 divide-y divide-gray-200 p-4'>
                          <Radio
                            value={claimPaymentPreferenceEnum.REIMBURSEMENT}
                            size='lg'
                          >
                            <Text className='ml-2 text-base'>
                              Reimburse my lease buyout penalty payment
                            </Text>
                          </Radio>
                        </Flex>
                      )}
                    </RadioGroup>
                    <FormError name='submission.paymentPreference' />
                  </ApplyFormField>
                </ApplyFormLayout>
                
                <Accordion
                  // defaultIndex={!savedPlansValue.property && [0]}
                  allowToggle
                  className='border rounded-md mt-12'
                >
                  <AccordionItem className='border-t-0 border-b rounded-b-md'>
                    <AccordionButton className='flex w-full gap-2 justify-between py-3'>
                      <Text className='font-medium text-base text-left'>
                        Need help submitting a claim?
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel className='border-t pt-3'>
                      <Text className='text-base text-gray-500 mb-3'>
                        If you need help submitting a claim, please reach out to
                        us directly.
                      </Text>
                      {/* Can't find property */}
                      <ContactCard small />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </ApplyFormContainer>
            </ApplyContent>
          </div>
        );
      }}
    </Formik>
  );
};

export default Edit;
