import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetSinglePlanQuery,
  useEmailLoginExchangeMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { setCredentials } from 'redux/features/auth/authSlice';
import LoadingPageLayout from 'common/layouts/loading-page';
import ErrorPageLayout from 'common/layouts/error-page';
import { selectIsLoggedIn } from 'redux/features/auth/authSlice';

const AttachPlan = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const planId = searchParams.get('planId');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [
    emailLoginExchange,
    { isLoading: isLoginLoading, isError: isLoginError, error: loginError },
  ] = useEmailLoginExchangeMutation();
  const {
    data: singlePlan,
    isLoading: isPlanLoading,
    isError: isPlanError,
    error: planError,
  } = useGetSinglePlanQuery(planId, { skip: !planId || !isLoggedIn });

  useEffect(() => {
    const attemptLogin = async () => {
      if (token) {
        try {
          const { data } = await emailLoginExchange(token).unwrap();
          toast.success('Login successful!');
          dispatch(
            setCredentials({
              isLoggedIn: true,
              token: data?.token,
              user: data?.user,
            })
          );
        } catch (err) {
          toast.error(err?.data?.msg || 'Login failed.');
          navigate('/login');
        }
      }
    };

    if (!isLoggedIn && token) {
      attemptLogin();
    }
  }, [token, isLoggedIn, dispatch, navigate, emailLoginExchange]);

  useEffect(() => {
    if (isLoggedIn && planId && !isPlanLoading && !isPlanError) {
      if (singlePlan) {
        console.log('Plan details:', singlePlan);
        // Replace with your actual navigation logic
        // Example:
        navigate(`/dashboard/plans/apply?planId=${planId}`);
      } else {
        toast.error('Plan not found.');
        // Example:
        navigate('/dashboard/plans/apply');
      }
    }
  }, [singlePlan, isLoggedIn, planId, isPlanLoading, isPlanError, navigate]);

  if (!planId) {
    return (
      <ErrorPageLayout
        title='No Plan ID'
        subtitle='Please contact support.'
        message='No plan ID was provided. Please contact support.'
      />
    );
  }

  if (!isLoggedIn && isLoginLoading) {
    return (
      <LoadingPageLayout
        title='Logging You In'
        subtitle='Please wait while we log you in'
        message="We're logging you into your account. This may take a few seconds."
      />
    );
  }

  if (!isLoggedIn && isLoginError) {
    return (
      <ErrorPageLayout
        title='Login Error'
        subtitle='Please try again.'
        message={loginError?.data?.message || 'Failed to Login'}      
      />
    );
  }

  if (isPlanLoading) {
    return (
      <LoadingPageLayout
        title='Loading Plan'
        subtitle='Please wait while we load your plan'
        message='We are loading your plan. This may take a few seconds.'
      />
    );
  }

  if (isPlanError) {
    return (
      <ErrorPageLayout  
        title='Plan Error'
        subtitle='Please try again.'
        message={planError?.data?.message || 'Failed to Load Plan'}
      />
    );
  }

  //If logged in and no plan, or not logged in still
  return (
    <LoadingPageLayout
      title={isLoggedIn ? 'Attaching plan...' : 'Redirecting to Log In...'}
      subtitle='Please wait while we load your plan'
      message='We are loading your plan. This may take a few seconds.'
    />
  );
};

export default AttachPlan;
