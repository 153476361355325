import { Formik } from 'formik';
import { useRef, useState, useEffect } from 'react';
import {
  useRequestSetupIntentMutation,
  useAddSetupIntentMutation,
  useFinalizeAClaimMutation,
  usePayClaimMutation,
  useUpdateClaimMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'common/utils/isEmpty';
import Payment from 'components/stripe/payment';
import Button from 'common/components/button';
import { doesClaimRequirePayment } from 'common/utils/misc';
import ApplyButtons from 'layouts/apply-buttons';
import { ApplyFormContainer, ApplyFormLayout } from 'common/layouts/apply-form';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { Flex, Text } from '@chakra-ui/react';
import { extractErrorMsg } from 'utils/errors';
import ClaimsPaymentSummaryCard from 'components/claims/claims-payment-summary';
import ContactCard from 'components/shared/contact-card';
import FinishSetupIntent from 'components/stripe/finish-setup-intent';
import { claimStatusEnum } from 'common/utils/enums';
import ApplyContent from 'components/apply-shared/apply-content';
import ApplyTitle from 'components/apply-shared/apply-title';
import FormatAsCurrency from 'common/utils/formatAsCurrency';

// There are two cases to deal with
// (1) Person wants to upload a new card
// (2) Person wants to use their existing method to pay

// In the case of adding a new payment method, this is what is required:

// requestSetupIntent -> clientSecret

// clientSecret -> Payment element for stripe becomes active, allowing you to give a credit card

// Completing the stipre payment means it will return to the returnUrl

// When you have the returnUrl, it will include a setup_intent in the query params

// With setup_intent, you can call addSetupIntent
//   -> Should explicitly set makeDefault as true, as this sets the default payment method for the user

// The user's default payment method is now updated

// You can automatically make a payment now, or handle it as a separate button

const ClaimPayment = ({ claim, onSuccess }) => {
  const [showNewCard, setShowNewCard] = useState(false);
  const [requestSetupIntent] = useRequestSetupIntentMutation();
  const [clientSecret, setSecret] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const setupIntent = searchParams.get('setup_intent');

  const [
    addSetupIntent,
    {
      isLoading: setupIntentLoading,
      isSuccess: setupIntentSuccess,
      isError: setupIntentError,
    },
  ] = useAddSetupIntentMutation();

  useEffect(() => {
    requestSetupIntent()
      .unwrap()
      .then((response) => {
        setSecret(response?.data?.clientSecret);
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to setup intent!');
      });
  }, []);

  useEffect(() => {
    if (setupIntent) {
      addSetupIntent(setupIntent, true)
        .unwrap()
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          toast.error(error.message, { toastId: 'setupIntentFailed' });
          console.error(error);
        });
    }
  }, [setupIntent]);

  return (
    <Flex className='col-span-2 flex-col gap-6'>
      <ClaimsPaymentSummaryCard claim={claim} />

      <Flex className='flex-col gap-3'>
        {showNewCard ? (
          <Button
            title='Use Saved Payment Method'
            theme='secondary'
            onClick={() => {
              setShowNewCard(false);
            }}
          />
        ) : (
          <Button
            title='Add a New Payment Method'
            theme='secondary'
            onClick={() => {
              setShowNewCard(true);
            }}
          />
        )}

        {showNewCard ? (
          clientSecret &&
          (!isEmpty(setupIntent) ? (
            <div>Finish Setup Intent</div>
          ) : (
            <div className='mt-6'>
              <Payment
                clientSecret={clientSecret}
                returnUrl={`${window.location.href}`}
              />
            </div>
          ))
        ) : (
          <Button
            title='Pay Using Saved Payment Method'
            onClick={() => onSuccess()}
          />
        )}
      </Flex>
    </Flex>
  );
};

const NoPayment = ({ claim, claimId }) => {
  const [finalizeClaim] = useFinalizeAClaimMutation();
  const navigate = useNavigate();

  return (
    <div className='col-span-2'>
      <Button
        title='Finalize Claim'
        onClick={() => {
          finalizeClaim({ claimId: claimId })
            .unwrap()
            .then(() => {
              navigate(`/dashboard/claims/${claimId}/review`);
            })
            .catch((error) => {
              const errMsg = extractErrorMsg(error, 'Finalize Failed');
              toast.error(errMsg);
            });
        }}
      />
    </div>
  );
};

const FinalizeClaim = ({ claim, claimId }) => {
  const formikRef = useRef(null);
  const [payClaim, { isLoading: payClaimLoading }] = usePayClaimMutation();
  const [finalizeClaim, { isLoading: finalizeClaimLoading }] =
    useFinalizeAClaimMutation();
  const [updateClaim, { isLoading: updateClaimLoading }] =
    useUpdateClaimMutation();
  const navigate = useNavigate();

  const requiresPayment = doesClaimRequirePayment(claim);
  const totalPayment =
    claim?.memberPayment?.deductible + claim?.memberPayment?.nonEligibleCharges;
  const handleReturn = () => {
    navigate(`/dashboard/claims/${claimId}`);
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{}}
      onSubmit={(values) => {
        let { confirmedDate, ...submissionWithoutConfirmedDate } =
          claim?.submission || {};

        let submissionValues = {
          claimId: claimId,
          submission: submissionWithoutConfirmedDate,
        };

        updateClaim(submissionValues)
          .unwrap()
          .then(() => {
            navigate(`/dashboard/claims/${claimId}/review`);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <div className='flex flex-col w-full h-full'>
            <ApplyTitle
              title={
                claim.status === claimStatusEnum.CONFIRMED
                  ? 'Finalized Claim Resolution'
                  : 'Finalize Claim Resolution'
              }
              subtitle={
                claim.status === claimStatusEnum.CONFIRMED
                  ? 'Your finalized claim resolution details'
                  : 'Please confirm your move-out date and finalize the claim.'
              }
              buttons={
                <ApplyButtons
                  loading={updateClaimLoading || payClaimLoading}
                  isValid={isValid}
                  moveOn={true}
                  onBackClick={handleSubmit}
                  onNextClick={handleReturn}
                  disableNext={claim.status !== claimStatusEnum.CONFIRMED}
                  disableBack={claim.status === claimStatusEnum.CONFIRMED}
                  backTitle='Change Move Out Date'
                  nextTitle='Return to Claim'
                />
              }
            />

            {/* Main Area */}
            <ApplyContent>
              {/* container */}
              <ApplyFormContainer>
                {claim.status !== claimStatusEnum.CONFIRMED ? (
                  <ApplyFormLayout className='pb-6'>
                    <Flex className='flex-col gap-2 col-span-2'>
                      <Text className='font-medium text-lg'>
                        By finalizing this claim, you are agreeing to the
                        resolution.
                      </Text>
                      <Text className='text-base text-gray-500'>
                        The move-out date you have chosen will be finalized and
                        will not be able to be changed once finalized.
                      </Text>
                      <Text className='text-base text-gray-500'>
                        If you have any concerns or questions about the
                        resolution, please reach out to our support team.
                      </Text>
                    </Flex>
                    <ContactCard small className='col-span-2' />
                  </ApplyFormLayout>
                ) : (
                  <ApplyFormLayout className='pb-6'>
                    <Flex className='flex-col gap-2 col-span-2'>
                      <Text className='font-medium text-lg'>
                        Your claim is finalized
                      </Text>
                      <Text className='text-base text-gray-500'>
                        Your claim has been finalized and is now complete.
                        Please save the details below for your records.
                      </Text>
                    </Flex>
                  </ApplyFormLayout>
                )}

                <ApplyFormLayout className='py-6'>
                  <Flex className='flex-col gap-2 col-span-2'>
                    <Text className='font-medium text-lg col-span-2'>
                      Claim Resolution Details
                    </Text>
                  </Flex>
                  <InfoField title='Move Out Date'>
                    {formatDateToHumanDate(claim?.submission?.confirmedDate)}
                  </InfoField>
                  {claim.status === claimStatusEnum.CONFIRMED ? (
                    <InfoField title='Finalized On'>
                      {formatDateToHumanDate(
                        claim?.statusChanges?.find(
                          (x) => x.status === claimStatusEnum.CONFIRMED
                        )?.updatedAt
                      )}
                    </InfoField>
                  ) : null}
                  {requiresPayment ? (
                    <InfoField title='Total Payment'>
                      {FormatAsCurrency(totalPayment, claim?.planId?.lease?.currency || 'USD')}
                    </InfoField>
                  ) : (
                    <InfoField title='Total Payment'>
                      No Payment Required
                    </InfoField>
                  )}
                  <InfoField title='Review' className='col-span-2'>
                    {claim?.review?.reason}
                  </InfoField>
                </ApplyFormLayout>

                {claim.status === claimStatusEnum.CONFIRMED ? (
                  <ApplyFormLayout className='py-6'>
                    <Flex className='flex-col gap-2 col-span-2'>
                      <ClaimsPaymentSummaryCard claim={claim} isComplete />
                    </Flex>
                  </ApplyFormLayout>
                ) : null}

                {claim.status !== claimStatusEnum.CONFIRMED ? (
                  <ApplyFormLayout className='py-6'>
                    {requiresPayment ? (
                      <ClaimPayment
                        claim={claim}
                        onSuccess={() => {
                          payClaim({ claimId: claimId })
                            .unwrap()
                            .then(() => {
                              navigate(`/dashboard/claims/${claimId}/review`);
                            })
                            .catch((error) => {
                              toast.error(
                                error.message ||
                                  'Failed to complete payment and finalize claim'
                              );
                            });
                        }}
                      />
                    ) : (
                      <NoPayment claim={claim} claimId={claimId} />
                    )}
                  </ApplyFormLayout>
                ) : null}
              </ApplyFormContainer>
            </ApplyContent>
          </div>
        );
      }}
    </Formik>
  );
};

export default FinalizeClaim;
