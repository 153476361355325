import { dateRangeStatusEnum } from "common/utils/enums";

// Helper to check if current time is within a given range
export const isWithinDateRange = (coverage, currentDate) => {
  if (!coverage) return false;
  const start = new Date(coverage.startDate);
  const end = new Date(coverage.endDate);
  return start <= currentDate && currentDate <= end;
};

// Helper to check status of current time within a given range
export const getDateRangeStatus = (coverage, currentDate) => {
  if (!coverage) return null;
  const start = new Date(coverage.startDate);
  const end = new Date(coverage.endDate);
  if (currentDate < start) return dateRangeStatusEnum.BEFORE;
  else if (currentDate > end) return dateRangeStatusEnum.AFTER;
  else return dateRangeStatusEnum.WITHIN;
};
