import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Tag,
  Text,
} from '@chakra-ui/react';
import Button from 'common/components/button';
import { getDateRangeStatus } from 'common/utils/dateRange';
import { dateRangeStatusEnum } from 'common/utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { getTerm } from 'common/utils/getTerm';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { useEffect, useMemo, useState } from 'react';
import { coverageTypes } from 'utils/enums';

const InfoDetail = ({ title, subtitle, children }) => {
  return (
    <AccordionItem className='w-full'>
      <AccordionButton className='flex text-left items-center justify-between px-6 py-3 gap-4 w-full hover:bg-gray-50 border-gray-200 mobile:justify-center'>
        <Flex className='flex w-full mobile:flex-col'>
          {/* Text */}
          <Flex className='flex-col items-start min-w-[180px]'>
            <h3 className='text-base'>{title}</h3>
          </Flex>
          {/* Limit */}
          {subtitle ? (
            <Flex
              className={`mobile:flex-row mobile:gap-2 text-base text-gray-500`}
            >
              <Text>{subtitle}</Text>
            </Flex>
          ) : null}
        </Flex>
        {children ? <AccordionIcon /> : null}
      </AccordionButton>
      {children ? (
        <AccordionPanel className='border-t px-6 py-4 flex flex-col gap-3 text-base text-gray-500'>
          {children}
        </AccordionPanel>
      ) : null}
    </AccordionItem>
  );
};

const MemberInfoCard = ({ title, subtitle, onClick, buttonText, description, children, amount, headerClassName }) => {
  return (
    <Flex className='rounded-lg border border-gray-200 flex flex-col'>
      <Flex className={`flex-col gap-6 w-full p-6`}>
        {/* Header */}
        <Flex className='w-full flex-col gap-6 sm:flex-row sm:justify-between items-center'>
          <Flex
            className={`w-full flex-col gap-1 pr-6 ${amount && 'sm:max-w-lg'} sm:mb-0 ${headerClassName}`}
          >
            <Text className='text-lg font-medium inline-flex items-center'>
              {title}
            </Text>
            <Text className='text-base text-gray-500'>{subtitle}</Text>
          </Flex>

          {onClick ? (
            <Button theme='secondary' title={buttonText} onClick={onClick} wFull={false} className='self-start sm:self-center' />
          ) : amount ? (
            amount
          ) : null}
        </Flex>

        {description ? (
          <Flex className='flex-col gap-1 text-sm text-gray-500'>
            {description}
          </Flex>
        ) : null}
      </Flex>

      {children ? (
        <Accordion
          allowMultiple
          className='flex w-full flex-col overflow-hidden divide-y divide-gray-200'
        >
          {children}
        </Accordion>
      ) : null}
    </Flex>
  );
};

export { InfoDetail, MemberInfoCard };
