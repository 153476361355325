import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex
} from '@chakra-ui/react';
import FormatAsCurrency from 'common/utils/formatAsCurrency';

const PaymentSummary = ({ claim, isComplete }) => {
  const deductible = claim?.memberPayment?.deductible;
  const nonEligibleCharges = claim?.memberPayment?.nonEligibleCharges;
  const total = deductible + nonEligibleCharges;

  const gridLayout = 'grid-cols-[1fr_auto_60px] sm:grid-cols-[200px_1fr_100px]'

  return (
    <Flex className={`flex-col gap-1 text-base`}>
      {/* due now text */}
      <Text className='font-medium text-lg'>Itemized Charges</Text>
      <Text className='mb-4 text-base text-gray-500'>
        {isComplete
          ? 'Your payment receipt includes the following charges:'
          : 'Your payment includes the following charges:'}
      </Text>

      <Flex
        className={`flex-col gap-1 text-base`}
      >
        <div className={`grid gap-2 w-full justify-between ${gridLayout}`}>
          <Text className='font-normal'>Deductible</Text>
          <Text className='hidden text-gray-500 sm:block'>
            The portion of the claim cost you’re responsible for paying before
            coverage applies.
          </Text>
          <Text className='text-right text-gray-500'>{FormatAsCurrency(deductible, 'USD')}</Text>
        </div>

        <div className={`grid gap-2 w-full justify-between ${gridLayout}`}>
          <Text className='font-normal'>Non-Eligible Charges</Text>
          <Text className='hidden text-gray-500 sm:block'>
            Non-eligible charges owed to your landlord to approve an early
            move-out, including leasing concessions and certain move-out fees,
            that are not covered by ReLease.
          </Text>
          <Text className='text-right text-gray-500'>{FormatAsCurrency(nonEligibleCharges, 'USD')}</Text>
        </div>

        {/* tax */}
        <div className={`grid gap-2 w-full justify-between ${gridLayout} pt-2 mt-2 border-t border-gray-200`} >
          <Text className='font-normal'>Applicable Taxes</Text>
          <Text className='text-gray-500'></Text>
          <Text className='text-right text-gray-500'>
            {FormatAsCurrency(0, 'USD')}
          </Text>
        </div>

        {/* Total */}
        <div className={`grid gap-2 w-full justify-between ${gridLayout} font-medium`}>
          <Text>Total</Text>
          <Text></Text>
          <Text className='text-right'>
            {FormatAsCurrency(total, 'USD')}
          </Text>
        </div>
      </Flex>
    </Flex>
  );
};

const ClaimsPaymentSummaryCard = ({
  claim,
  isComplete = false,
  card = false,
}) => {
  const plan = claim?.planId;
  const total =
    claim?.memberPayment?.deductible + claim?.memberPayment?.nonEligibleCharges;

  if (total === 0) {
    return null;
  } else {
    return (
      <Flex
        className={`flex-col ${
          card ? 'rounded-md border border-gray-200' : ''
        }`}
      >
        <Accordion
          allowMultiple
          className='flex w-full flex-col rounded-md overflow-hidden'
        >
          <AccordionItem className='border-none'>
            <div
              className={`flex mobile:flex-col gap-4 justify-between items-center mobile:items-start ${
                card ? 'p-6' : 'pb-6'
              }`}
            >
              <div className='flex flex-col gap-1'>
                <h2 className='capitalize text-lg font-medium'>
                  {isComplete ? 'Payment Receipt' : 'Payment Details'}
                </h2>
                <Text className='text-base text-gray-500'>
                  Here’s a summary of your payment to finalize your claim
                  resolution.
                </Text>
              </div>

              {/* Quick view of monthly/onetime totals */}
              <Flex className='gap-5 divide-x divide-gray-200 items-center'>
                <div className='flex flex-col items-end mobile:items-start'>
                  <div className='text-xl font-medium'>
                    ${total.toFixed(2)} {plan?.lease?.currency}
                  </div>
                  <div className='text-base text-gray-500'>Total Payment</div>
                </div>
              </Flex>
            </div>

            {/* Accordion to expand to show all */}
            <AccordionButton
              className={`py-2 px-6 hover:bg-gray-50 border-gray-200 ${
                card ? 'border-t' : 'border rounded-md'
              }`}
            >
              <div className='w-full text-left text-base font-medium py-1'>
                Show Expanded Payment {isComplete ? 'Receipt' : 'Details'}
              </div>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel className={`flex flex-col gap-8 p-6 ${card ? 'border-t' : 'border rounded-md mt-4'}`}>
              {/* payment information */}
              <PaymentSummary claim={claim} isComplete={isComplete} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  }
};

export default ClaimsPaymentSummaryCard;
