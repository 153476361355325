import { FiLogOut } from 'react-icons/fi';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import LogoImage from 'common/components/logo-image';
import Button from 'common/components/button';

const ApplyContainer = ({ children }) => {
  return (
    <div className='w-full h-[100dvh] flex flex-col px-5 py-6 gap-6 mobile:pb-0'>
      {children}
    </div>
  );
};

const ApplyHeader = ({ onClick }) => {
  return (
    <div className='w-full flex justify-between items-center'>
      <LogoImage className='ml-5 mobile:ml-3' size='s' />

      {/* Buttons */}
      <div className='w-fit flex gap-2'>
        <Button onClick={() => window.open('mailto:members@releaserent.com', '_blank')} title='Get Help' theme='secondary'/>
      </div>
    </div>
  );
};

const ApplyMain = ({ children }) => {
  return (
    <div className='h-full min-w-screen flex gap-8 overflow-hidden mobile:flex-col mobile:gap-5 no-scrollbar'>
      {children}
    </div>
  );
};

const ApplyAside = ({
  children,
  save,
  saveText = 'Save & Continue Later',
  saveCondition = true,
  cancel,
  cancelText = 'Cancel Application',
  cancelCondition = true,
}) => {
  return (
    <aside className='h-full w-full max-w-[240px] relative flex flex-col gap-20 mobile:max-w-none mobile:px-2 mobile:h-fit'>
      {/* progress */}
      <div className='flex flex-col gap-4 w-full mobile:flex-row mobile:justify-between mobile:bg-gray-200 mobile:gap-0 mobile:rounded-full mobile:overflow-hidden'>
        {children}
      </div>

      {/* save and close application */}
      <div className='flex flex-col w-full px-3 mt-auto mobile:hidden'>
        {/* save and continue later */}
        {saveCondition && save ? (
          <p
            onClick={save}
            className='cursor-pointer hover:bg-gray-100 text-md font-medium border-t border-gray-200 px-3 py-3 flex items-center gap-3'
          >
            <FiLogOut className='text-lg text-gray-500' />
            <span>{saveText}</span>
          </p>
        ) : null}

        {/* cancel */}
        {cancelCondition && cancel ? (
          <p
            onClick={cancel}
            className='cursor-pointer hover:bg-gray-100 border-y border-gray-200 text-md font-medium py-3 px-3 flex items-center gap-3'
          >
            <HiMiniInformationCircle className='text-lg text-gray-500' />
            <span>{cancelText}</span>
          </p>
        ) : null}
      </div>
    </aside>
  );
};

export { ApplyMain, ApplyAside, ApplyHeader, ApplyContainer };
