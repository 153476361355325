import { useSearchParams } from 'react-router-dom';
import WrapInputLabel from 'common/components/wrap-input-label';
import { Field, Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import * as Yup from 'yup';
import ApplyButtons from 'layouts/apply-buttons';
import {
  useCreatePlanMutation,
  useFindPropertiesQuery,
  useFindPropertyAddressesQuery,
  useUploadRequestUrlMutation,
  useUpdateSinglePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { extractErrorMsg } from 'utils/errors';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'common/layouts/apply-form';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';
import ContactCard from 'components/shared/contact-card';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { propertyStatusEnum } from 'utils/enums';
import Input from 'common/components/input';
import Button from 'common/components/button';
import EmptyPage from 'common/components/empty-page';
import { FaArrowRight, FaCity, FaMountainCity } from 'react-icons/fa6';
import { FaSearch } from 'react-icons/fa';
import FormError from 'common/components/form-error';

const PlanStepSelectProperty = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const isEdit = searchParams.get('isEdit');

  // State management
  const [citySearchText, setCitySearchText] = useState('');
  const [propertySearchText, setPropertySearchText] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [locationCleared, setLocationCleared] = useState(false);
  const [propertyCleared, setPropertyCleared] = useState(false);

  // Redux hooks
  const savedPlansValue = useSelector(selectPlansValues);
  const dispatch = useDispatch();

  // API mutations
  const [
    createAPlan,
    { isLoading: createPlanLoading, isSuccess: isCreatePlanSuccess },
  ] = useCreatePlanMutation();

  const [
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();

  const [uploadRequestUrl] = useUploadRequestUrlMutation();

  // User tracking
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // API queries
  const { data: propertyAddresses, isLoading: isCityLoading } =
    useFindPropertyAddressesQuery(
      { city: citySearchText },
      { skip: !citySearchText.trim() }
    );

  // Wrap the selected location so it's updated when the city is changed
  const address = useMemo(
    () =>
      selectedLocation
        ? {
            city: selectedLocation.city,
            state: selectedLocation.state,
            country: selectedLocation.country,
          }
        : {},
    [selectedLocation]
  );
  
  const { data: propertiesQuery, isError, error, findPropertiesLoading } =
    useFindPropertiesQuery(
      {
        sort: '-createdAt',
        address,
        status: [
          propertyStatusEnum.OPTIN,
          propertyStatusEnum.OPTOUT,
          propertyStatusEnum.REQUIRED,
        ],
      },
      { skip: !selectedLocation }
    );

  // Helper functions
  const formatPropertyDisplayName = (property) => {
    const name = property.name || 'Building';
    const address = property.address?.streetAddress1;
    const city = property.address?.city;

    return {
      name,
      addressLine: address ? `${address}, ${city}` : city || '',
      fullDisplayName: address
        ? `${name}, ${address}, ${city}`
        : `${name}, ${city}`,
    };
  };

  // Side effects
  useEffect(() => {
    if (isCreatePlanSuccess || isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isCreatePlanSuccess, isUpdatePlanSuccess, clearSearchParams]);

  // Debounced city search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // City search logic is handled by the hook
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [citySearchText]);

  // Debounced property search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Property search logic is handled by the hook
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [propertySearchText, selectedLocation]);

  // Load saved values
  useEffect(() => {
    if (savedPlansValue?.property && !locationCleared) {      
      setSelectedLocation({
        city: savedPlansValue?.property?.address?.city,
        state: savedPlansValue?.property?.address?.state,
        country: savedPlansValue?.property?.address?.country,
      });
    }

    if (savedPlansValue?.property && propertiesQuery && !propertyCleared) {
      // Look for the property in the query results
      const foundProperty = propertiesQuery.find(
        (p) => p.id === savedPlansValue.property.id
      );

      if (foundProperty) {
        setSelectedProperty(savedPlansValue.property);
        setPropertyDetails(foundProperty);
      } else {
        // If not found in current results, just store the ID
        setSelectedProperty(savedPlansValue.property);
      }
    }
  }, [savedPlansValue, propertiesQuery]);

  // Log selected location
  useEffect(() => {
    console.log('saved plan values', savedPlansValue);
  }, [savePlansValues]);

  const fieldsDisabled = false;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        property: savedPlansValue?.property || '',
        cityData: savedPlansValue?.cityData || null,
      }}
      validationSchema={Yup.object().shape({
        cityData: Yup.object().required('Please select a city'),
        property: Yup.string().required('Please select a property'),
      })}
      onSubmit={(values) => {
        const submissionValues = {
          property: values?.property,
        };

        let mutation = createAPlan;
        if (planId) {
          submissionValues.planId = planId;
          mutation = updatePlan;
        } else if (savedPlansValue.id) {
          submissionValues.planId = savedPlansValue.id;
          mutation = updatePlan;
        }

        trackFormSubmission(submissionValues);

        mutation(submissionValues)
          .unwrap()
          .then((data) => {
            dispatch(savePlansValues(data || {}));
            onNextClick();
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        isValid,
        dirty: isDirty,
        handleSubmit,
        setFieldTouched,
        errors,
        touched,
        submitCount,
      }) => {
        return (
          <Form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col w-full h-full'
          >
            <ApplyTitle
              title='Select Property'
              subtitle='Pick the property you are renting.'
              buttons={
                <ApplyButtons
                  loading={createPlanLoading || updatePlanLoading}
                  isValid={isValid && (isDirty || savedPlansValue?.property)}
                  handleSubmit={isDirty ? handleSubmit : onNextClick}
                  moveOn={fieldsDisabled}
                  onNextClick={onNextClick}
                  disableBack
                />
              }
            />

            {/* main content */}
            <ApplyContent>
              {/* container */}
              <ApplyFormContainer>
                {/* City Selection */}
                <ApplyFormLayout>
                  <h2 className='font-medium col-span-2'>
                    {selectedLocation ? 'Selected City' : 'Select City'}
                  </h2>
                  <ApplyFormField>
                    {!selectedLocation ? (
                      <>
                        <WrapInputLabel isRequired>City</WrapInputLabel>
                        <Input
                          placeholderText='Search for city'
                          type='text'
                          value={citySearchText}
                          onChange={(e) => {
                            const value = e.target.value;
                            console.log('value: ', value);
                            setCitySearchText(value);
                            if (!value) {
                              setCitySearchText('');
                            }
                          }}
                          disabled={!!selectedLocation}
                        />

                        {isCityLoading ? (
                          <EmptyPage
                            isLoading={isCityLoading}
                            title='Loading Cities'
                            subtitle='Please wait while we fetch properties and cities.'
                          />
                        ) : !selectedLocation &&
                          propertyAddresses &&
                          propertyAddresses.length > 0 &&
                          citySearchText ? (
                          <Flex className='mt-2 flex-col rounded-md border border-gray-200 divide-y divide-gray-200'>
                            {propertyAddresses
                              .slice(0, 5)
                              .map((address, index) => (
                                <Flex
                                  key={`${address.city}-${address.state}-${index}`}
                                  onClick={() => {
                                    const locationData = {
                                      city: address.city,
                                      state: address.state,
                                      country: address.country,
                                    };
                                    setSelectedLocation(locationData);
                                    setFieldValue('cityData', locationData);
                                    setFieldTouched('cityData', true);
                                    setCitySearchText('');
                                    setSelectedProperty(null);
                                    setPropertyDetails(null);
                                    setFieldValue('property', '');                                    
                                  }}
                                  className='py-3 pr-3 pl-4 text-base w-full gap-3 flex-col sm:flex-row sm:justify-between sm:items-center'
                                >
                                  <Flex className='justify-between sm:justify-normal sm:flex-col font-medium'>
                                    {address.city}
                                    <Text className='text-gray-500 font-normal'>
                                      {address.state}, {address.country}
                                    </Text>
                                  </Flex>
                                  <Button
                                    title='Select City'
                                    wFull={false}
                                    theme='secondary'
                                    Icon={FaArrowRight}
                                  />
                                </Flex>
                              ))}
                          </Flex>
                        ) : !selectedLocation && isEmpty(citySearchText) ? (
                          <EmptyPage
                            PageIcon={FaSearch}
                            title='Search for a City'
                            subtitle="Enter the name of the city where the property you're renting is located."
                          />
                        ) : !selectedLocation ? (
                          <EmptyPage
                            PageIcon={FaSearch}
                            title='No Properties Found'
                            subtitle='No properties were found matching your search. Please try searching a different city, or check the help section below.'
                          />
                        ) : null}

                        {touched.cityData && errors.cityData ? (
                          <FormError name='cityData' />
                        ) : null}
                      </>
                    ) : (
                      <Flex className='border border-gray-200 rounded-md py-3 pr-3 pl-4 text-base w-full justify-between gap-3 flex-col sm:flex-row sm:justify-between sm:items-center'>
                        <Flex className='justify-between sm:justify-normal sm:flex-col font-medium'>
                          {selectedLocation.city}
                          <Text className='text-gray-500 font-normal'>
                            {selectedLocation.state}, {selectedLocation.country}
                          </Text>
                        </Flex>
                        <Button
                          title='Change City'
                          wFull={false}
                          theme='secondary'
                          Icon={FaSearch}
                          onClick={() => {
                            setSelectedLocation(null);
                            setFieldValue('cityData', null);
                            setFieldValue('property', '');
                            setSelectedProperty(null);
                            setPropertyDetails(null);
                            setPropertySearchText('');
                            setLocationCleared(true);
                            setPropertyCleared(true);
                          }}
                        />
                      </Flex>
                    )}
                  </ApplyFormField>
                </ApplyFormLayout>

                {/* Select Property - Only show if city is selected */}
                {selectedLocation && (
                  <ApplyFormLayout className='mt-8 pt-8'>
                    <h2 className='font-medium col-span-2'>
                      {selectedProperty
                        ? 'Selected Property'
                        : 'Select Property'}
                    </h2>
                    <ApplyFormField>
                      {!selectedProperty ? (
                        <>
                          <WrapInputLabel isRequired>Property</WrapInputLabel>
                          {findPropertiesLoading ? (
                            <EmptyPage
                              isLoading={findPropertiesLoading}
                              title='Loading Properties'
                              subtitle={`Please wait while we fetch properties in ${selectedLocation.city}.`}
                            />
                          ) : !selectedProperty &&
                            propertiesQuery &&
                            propertiesQuery.length > 0 ? (
                            <Flex className='mt-2 flex-col rounded-md border border-gray-200 divide-y divide-gray-200'>
                              {propertiesQuery.map((property) => {
                                const { name, addressLine, fullDisplayName } =
                                  formatPropertyDisplayName(property);
                                return (
                                  <Flex
                                    key={property.id}
                                    onClick={() => {
                                      setSelectedProperty(property.id);
                                      setPropertyDetails(property);
                                      setFieldValue(
                                        'property',
                                        property.id
                                      ).then(() => {
                                        setFieldTouched('property', true);
                                      });
                                    }}
                                    className='py-3 pr-3 pl-4 text-base w-full gap-3 flex-col sm:flex-row sm:justify-between sm:items-center'
                                  >
                                    <Flex className='flex-col font-medium'>
                                      {name}
                                      <Text className='text-gray-500 font-normal'>
                                        {addressLine}
                                      </Text>
                                    </Flex>
                                    <Button
                                      title='Select Property'
                                      wFull={false}
                                      theme='secondary'
                                      Icon={FaArrowRight}
                                    />
                                  </Flex>
                                );
                              })}
                            </Flex>
                          ) : !selectedProperty &&
                            propertiesQuery &&
                            propertiesQuery.length === 0 ? (
                            <EmptyPage
                              PageIcon={FaSearch}
                              title='No Properties Found'
                              subtitle='No properties were found matching your search. Please try searching a different city, or check the help section below.'
                            />
                          ) : null}

                          {submitCount > 0 && touched.property && errors.property ? (
                            <FormError name='property' />
                          ) : null}
                        </>
                      ) : (
                        <Flex className='border border-gray-200 rounded-md py-3 pr-3 pl-4 text-base w-full justify-between gap-3 flex-col sm:flex-row sm:justify-between sm:items-center'>
                          <Flex className='flex-col font-medium'>
                            {propertyDetails?.name || 'Building'}
                            <Text className='text-gray-500 font-normal'>
                              {propertyDetails?.address?.streetAddress1},{' '}
                              {propertyDetails?.address?.city}
                            </Text>
                          </Flex>
                          <Button
                            title='Change Property'
                            wFull={false}
                            theme='secondary'
                            Icon={FaSearch}
                            onClick={() => {
                              setSelectedProperty(null);
                              setPropertyDetails(null);
                              setFieldValue('property', '');
                              setPropertyCleared(true);
                            }}
                          />
                        </Flex>
                      )}
                    </ApplyFormField>
                  </ApplyFormLayout>
                )}

                <Accordion allowToggle className='border rounded-md mt-6'>
                  <AccordionItem className='border-t-0 border-b rounded-b-md'>
                    <AccordionButton className='flex w-full gap-2 justify-between py-3'>
                      <Text className='font-medium text-base text-left'>
                        Your city or building not listed?
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel className='border-t pt-5'>
                      <Text className='text-base mb-4 text-gray-500'>
                        If you can't find your property in the list above,
                        please provide the name and address of your building to
                        us in an email.
                      </Text>
                      {/* Can't find property */}
                      <ContactCard small />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </ApplyFormContainer>
            </ApplyContent>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlanStepSelectProperty;
