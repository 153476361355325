/* eslint-disable no-unused-vars */
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import ContactForm from 'components/settings/contact-form';
import PasswordForm from 'components/settings/password-form';
import EmptyPage from 'common/components/empty-page';
import { FaUserAlt } from 'react-icons/fa';

const AccountSettings = () => {
  // HOOKS
  const { data: userDetails, isLoading: getUserDetailsLoading } =
    useRetrieveUserDetailsQuery();

  return getUserDetailsLoading ? (
    <EmptyPage
      title='Loading Your Account Information'
      subtitle='Please wait while we load your account information.'
      isLoading={true}
    />
  ) : isEmpty(userDetails) ? (
    <EmptyPage
      title='No Account Information Found'
      subtitle='We could not find any account information for you.'
      PageIcon={FaUserAlt}
    />
  ) : (
    <>
      {/* Contact Information */}
      <ContactForm user={userDetails} />

      {/* Reset Password */}
      <PasswordForm />
    </>
  );
};

export default AccountSettings;
