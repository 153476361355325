/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { planStatusEnum } from 'common/utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isPropertyBulkBilling } from 'common/utils/misc';
import StatusLabel from 'common/components/status-label';
import { InfoField } from 'common/components/info-card';

const PlansTable = ({ plans }) => {
  // DATA INTIAILIZATION
  const navigate = useNavigate();

  // HANDLERS
  const getNavURL = (plan, id) => {
    // Figure out which step to navigate to based on conditions we know about the plan
    let navigationUrl = '';
    let step = 0;
    const status = plan?.status;

    // Check if the plan is complete, rejected, or cancelled
    // These should not be clickable
    const isComplete =
      status === planStatusEnum.COMPLETE ||
      status === planStatusEnum.REJECTED ||
      status === planStatusEnum.CANCELLED;

    // Check if the plan is active
    // These should navigate to the plan details page
    const isActive = status === planStatusEnum.ACTIVE;

    // Check if the plan is in review
    // These should navigate to the last step (7)
    const inReview = status === planStatusEnum.REVIEW;

    // Check if the plan has a payment method
    // These should navigate to the post-payment confirmation page
    const hasPaymentMethod =
      isPropertyBulkBilling(plan.property) ||
      plan.coverage?.cancelAnytime?.payment?.stripe?.id ||
      plan.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
      plan.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;

    // Check if the plan has the minimum required information to select coverages
    // These should navigate to the coverage selection page
    const hasLease =
      plan?.lease?.address &&
      plan?.lease?.startDate &&
      plan?.lease?.endDate &&
      plan?.lease?.signDate &&
      plan?.lease?.monthlyRent;

    // Check if the plan has a property selected
    // These should navigate to the personal info page, otherwise they should navigate to the property selection page
    const hasProperty = plan?.property !== '' || plan?.lease?.property !== '';

    // Set the navigation URL and inferred step number based on the conditions above
    if (isComplete) {
      navigationUrl = '';
    } else if (isActive) {
      navigationUrl = `/dashboard/plans/${id}`;
    } else if (inReview) {
      navigationUrl = `/dashboard/plans/apply?step=6&planId=${id}`;
      step = 7;
    } else if (hasPaymentMethod) {
      navigationUrl = `/dashboard/plans/apply?step=5&planId=${id}`;
      step = 5;
    } else if (hasLease) {
      navigationUrl = `/dashboard/plans/apply?step=3&planId=${id}`;
      step = 3;
    } else if (hasProperty) {
      navigationUrl = `/dashboard/plans/apply?step=1&planId=${id}`;
      step = 1;
    } else {
      navigationUrl = `/dashboard/plans/apply?step=0&planId=${id}`;
      step = 0;
    }

    return { navigationUrl, step };
  };

  return (
    <table className='flex flex-col w-full'>
      {/* body */}
      <tbody className='w-full rounded-lg border border-gray-200 divide-y'>
        {plans.map((plan, index) => {
          return (
            <>
              <tr
                key={index}
                onClick={() => navigate(getNavURL(plan, plan.id).navigationUrl)}
                className='grid grid-cols-2 w-full hover:bg-gray-50 px-3 py-2 cursor-pointer justify-center sm:grid-cols-[2fr_0.5fr_0.5fr]'
              >
                {/* address, start date, end date */}
                <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-start col-span-2 sm:col-span-1'>
                  <InfoField title='Address'>
                    {plan?.lease?.address ? (
                      <>
                        {plan?.lease?.address?.streetAddress1}
                        {plan?.lease?.address?.streetAddress2 && ', '}
                        {plan?.lease?.address?.streetAddress2},{' '}
                        {plan?.lease?.address?.city},{' '}
                        {plan?.lease?.address?.state},{' '}
                        {plan?.lease?.address?.postalCode}
                      </>
                    ) : (
                      'No address'
                    )}
                  </InfoField>
                </td>

                {/* started application */}
                <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-start justify-center'>
                  <InfoField title='Started On'>
                    {formatDateToHumanDate(plan?.createdAt || '')}
                  </InfoField>
                </td>

                {/* status */}
                <td className='w-full p-3 flex flex-col gap-1 justify-center items-end'>
                  <StatusLabel
                    status={plan.status}
                    size='sm'
                  />
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default PlansTable;
