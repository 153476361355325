import { Flex, Tag, Text, Icon } from '@chakra-ui/react';
import FileUploadInput from 'common/components/file-upload-input';
import WrapInputLabel from 'common/components/wrap-input-label';
import { ApplyFormField } from 'common/layouts/apply-form';
import { useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
import { HiCheckBadge } from 'react-icons/hi2';
import { documentStatusEnum } from 'utils/enums';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import {
  useAddDocToUserMutation,
  useDeleteDocFromUserMutation,
  useUploadRequestUrlMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import { uploadFileToS3 } from 'utils/uploadS3';
import { extractErrorMsg } from 'utils/errors';
import Button from 'common/components/button';
import { useNavigate } from 'react-router-dom';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import CopyLink from 'common/components/copy-link';

const VerifyIdentity = ({ user }) => {
  const [idDoc, setIDDoc] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    url: '',
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState({
    isOpen: false,
    docId: '',
  });

  const { trackCustomEvent } = useUserTracker();

  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const [addDocToUser] = useAddDocToUserMutation();
  const [deleteDocFromuUser] = useDeleteDocFromUserMutation();
  const navigate = useNavigate();

  return (
    <Flex className='flex-col w-full gap-12'>      

      {user?.idVerification !== documentStatusEnum.VERIFIED ? (
        <Flex className='flex-col gap-6 w-full'>
          <Flex className='flex-col gap-1'>
            <h2 className='text-lg font-medium'>Upload a New ID Document for Verification</h2>
            <Text className='text-base text-gray-500'>
              For identify verification, please upload a government-issued ID,
              such as a driver’s license, passport, or citizenship card. Ensure
              the ID is clear and all details are visible.
            </Text>
          </Flex>
          <ApplyFormField>
            <WrapInputLabel isRequired>Upload ID</WrapInputLabel>

            {/* file upload input */}
            <FileUploadInput
              disableUpload={idDoc.isLoading}
              isUploadError={idDoc.isError}
              isUploadLoading={idDoc.isLoading}
              isUploadSuccess={idDoc.isSuccess}
              handlePDFClear={() => {
                setIDDoc((old) => ({
                  ...old,
                  isLoading: false,
                  isSuccess: false,
                  isError: false,
                  url: '',
                }));
              }}
              handlePDFUpload={async (file) => {
                let uploadUrlReq;
                setIDDoc((old) => ({ ...old, isLoading: true }));
                let fileTrack = { fileName: file.name, size: file.size };

                // Get the signing url for uploading to s3
                try {
                  trackCustomEvent('fileUploadSelection', {
                    name: 'idUrl',
                    file: fileTrack,
                  });
                  trackCustomEvent('s3UrlUploadRequest', {
                    name: 'idUrl',
                    file: fileTrack,
                  });
                  uploadUrlReq = await uploadRequestUrl({
                    fileName: file.name,
                    contentType: file.type,
                  }).unwrap();
                } catch (e) {
                  const errMsg =
                    e?.msg ||
                    e?.data?.msg ||
                    e?.message ||
                    'failed to upload ID document';
                  setIDDoc((old) => ({
                    ...old,
                    isLoading: false,
                    fileName: file.name,
                    isError: true,
                  }));
                  toast.error(errMsg);
                  trackCustomEvent('s3UrlUploadRequestError', {
                    name: 'idUrl',
                    file: fileTrack,
                    error: errMsg,
                  });
                  return;
                }

                if (
                  !uploadUrlReq?.data?.uploadUrl ||
                  !uploadUrlReq?.data?.viewUrl
                ) {
                  setIDDoc((old) => ({
                    ...old,
                    isLoading: false,
                    fileName: file.name,
                    isError: true,
                  }));
                  toast.error('failed to upload ID document');
                }

                // Upload the file to s3

                try {
                  trackCustomEvent('s3FileUploadRequest', {
                    name: 'idUrl',
                    file: fileTrack,
                    s3Url: uploadUrlReq?.data,
                  });
                  await uploadFileToS3({
                    s3Url: uploadUrlReq?.data?.uploadUrl,
                    file,
                  });
                  trackCustomEvent('s3FileUploadSuccess', {
                    name: 'idUrl',
                    file: fileTrack,
                    s3Url: uploadUrlReq?.data,
                  });

                  // Have to set the uploaded file url on the id document
                  setIDDoc((old) => ({
                    ...old,
                    url: uploadUrlReq?.data?.viewUrl,
                    fileName: file.name,
                    isLoading: false,
                    isError: false,
                    isSuccess: true,
                  }));

                  toast.info(
                    uploadUrlReq?.status || 'id has been uploaded successfully'
                  );
                } catch (e) {
                  setIDDoc((old) => ({
                    ...old,
                    fileName: file.name,
                    isLoading: false,
                    isError: true,
                    isSuccess: false,
                  }));
                  const errMsg = extractErrorMsg(
                    e,
                    'failed to upload id document'
                  );
                  toast.error(errMsg);
                  trackCustomEvent('s3FileUploadError', {
                    name: 'idUrl',
                    file: fileTrack,
                    s3Url: uploadUrlReq?.data,
                    error: errMsg,
                  });
                  return;
                }
              }}
            />
          </ApplyFormField>

          <div>
            <Button
              isLoading={idDoc.isLoading}
              disabled={!idDoc.isSuccess}
              onClick={() => {
                // update the user's id verification status
                // and the url of the uploaded document
                const date = new Date();
                const currentDate =
                  date.getFullYear() +
                  '-' +
                  String(date.getMonth() + 1).padStart(2, '0') +
                  '-' +
                  String(date.getDate()).padStart(2, '0');
                // Example: "2024-12-16"
                const documentName = 'ID uploaded ' + currentDate;

                addDocToUser({
                  name: documentName,
                  url: idDoc.url,
                })
                  .unwrap()
                  .then(() => {
                    toast.info('ID has been uploaded successfully');
                    navigate('/dashboard/settings');
                  })
                  .catch((e) => {
                    const errMsg =
                      e?.msg ||
                      e?.data?.msg ||
                      e?.message ||
                      'failed to upload ID document';
                    toast.error(errMsg);
                  });
              }}
              title='Submit ID for Verification'
              wFull={false}
            />
          </div>
        </Flex>
      ) : null}

      <Flex className='flex-col gap-6'>
        <Flex className='flex-col gap-1'>
          <h2 className='text-lg font-medium'>Uploaded ID Documents</h2>
          <Text className='text-base text-gray-500'>
            Manage and view your previously uploaded ID documents
          </Text>
        </Flex>
        <table className='w-full text-base'>
          <tbody className='flex flex-col rounded-md border border-gray-200 divide-y divide-gray-200'>
            {user?.documents?.map((doc, index) => (
              <tr key={index} className='grid grid-cols-[1fr_auto] items-center'>
                <td className='px-6 py-2 font-medium flex gap-3'>
                  <span className='text-base uppercase'>{doc.name}</span>
                  <span className='text-base font-normal text-gray-500 truncate'>
                    Uploaded
                  </span>
                </td>
                <td className='px-6 py-2'>
                  <Button
                    title='View Document'
                    theme='secondary'
                    onClick={() => {
                      window.open(doc.url, '_blank');
                    }}
                  />
                </td>
                {/* Commented out button to delete while API is updated to get document ID */}
                {/* <td className='px-2 py-1'>
                  <Button 
                    title='Delete'
                    theme='secondary'
                    onClick={() => setConfirmModalOpen({ isOpen: true, docId: doc.id })}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>

      {/* confirm modal */}
      {confirmModalOpen.isOpen ? (
        <ConfirmActionModal
          action='delete this document'
          onCancel={() => setConfirmModalOpen(false)}
          onConfirmClick={() => {
            // delete the document
            // Commented out while API is updated to get document ID
            // deleteDocFromuUser({ docId: confirmModalOpen.docId })
            //   .unwrap()
            //   .then(() => {
            //     toast.info('Document has been deleted');
            //     setConfirmModalOpen({ isOpen: false, docId: '' });
            //   })
            //   .catch((e) => {
            //     const errMsg =
            //       e?.msg ||
            //       e?.data?.msg ||
            //       e?.message ||
            //       'failed to delete document';
            //     toast.error(errMsg);
            //   });
          }}
        />
      ) : null}
    </Flex>
  );
};

export default VerifyIdentity;
