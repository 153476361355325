import { Flex, Icon, RadioGroup, Skeleton, Tag, Text } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import Button from 'common/components/button';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  saveCoverageSelection,
  selectPlansValues,
  selectCoverageSelection,
} from 'redux/features/plans/plansSlice';
import { planPaymentMode, coverageTypes } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { FaCheck, FaCircleXmark, FaPlus } from 'react-icons/fa6';
import PlanInfoCard from 'components/plans/plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentPlanCard from 'components/plans/payment-plan-card';
import { DisclosureSection } from 'components/coverages/disclosure-card';
import {
  CoveragePrice,
  PrePurchaseCoverageCard,
} from 'components/coverages/coverage-card';
import ApplyButtons from 'layouts/apply-buttons';
import { getTerm } from 'common/utils/getTerm';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { useState } from 'react';
import { isWithinDateRange } from 'common/utils/dateRange';
import EmptyPage from 'common/components/empty-page';
import { InfoDetail } from 'components/shared/member-info-card';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { FaExclamationCircle } from 'react-icons/fa';

const PlanStepChooseCoverage = ({
  onBackClick,
  onNextClick,
  isGettingPlan,
  toggleCancel,
}) => {
  // HOOKS
  const { trackPageLoad, trackCustomEvent } = useUserTracker();
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);
  // For some reason I can't use the tracker element, causes weird page loading issues
  useEffect(() => {
    trackPageLoad('PlanStepChooseCoverage');
  }, []);

  // DATA INITIALIZATION
  const dispatch = useDispatch();
  const plansValue = useSelector(selectPlansValues);
  const coverageSelection = useSelector(selectCoverageSelection);
  const { data: plan, isLoading: isGettingSinglePlan } = useGetSinglePlanQuery(
    plansValue?.id,
    {
      skip: isEmpty(plansValue?.id),
    }
  );

  const singlePlan = plan || plansValue;

  // Check eligibility of coverages
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  // Shorthands for coverage types
  const cancelAnytime = singlePlan?.coverage?.cancelAnytime;
  const leaveAnytime = singlePlan?.coverage?.leaveAnytime;

  const leaveAnytimeEligible = useMemo(() => {
    const inRange = isWithinDateRange(
      {
        startDate: now,
        endDate: leaveAnytime?.endDate,
      },
      now
    );
    const length =
      getTerm(singlePlan?.lease?.startDate, singlePlan?.lease?.endDate) >= 6;
    const allowed = singlePlan?.property?.leaveAnytimeEnabled;
    return inRange && length && allowed;
  }, [now, leaveAnytime]);

  const cancelAnytimeEligible = useMemo(() => {
    const inRange = isWithinDateRange(
      {
        startDate: now,
        endDate: cancelAnytime?.endDate,
      },
      now
    );
    const length =
      getTerm(singlePlan?.lease?.signDate, singlePlan?.lease?.startDate) > 1;
    const allowed = singlePlan?.property?.cancelAnytimeEnabled;
    return inRange && length && allowed;
  }, [now, cancelAnytime]);

  const hasLimitedCoverage = useMemo(
    () => leaveAnytime?.endDate < singlePlan?.lease?.endDate,
    [leaveAnytime, plan]
  );

  const program = singlePlan?.property?.owner?.program?.deductible;

  const concessions = singlePlan?.lease?.concessions?.repayable
    ? (singlePlan?.lease?.concessions?.months ?? 0) *
      (singlePlan?.lease?.monthlyRent ?? 0) *
      (singlePlan?.lease?.concessions?.discount ?? 0)
    : 0;

  // Set up payment amounts
  const formatAmount = (amount) => {
    return FormatAsCurrency(amount, singlePlan?.lease?.currency);
  };

  const rent = singlePlan?.lease?.monthlyRent;
  const leaveAnytimeAmount =
    leaveAnytime?.payment?.onetime?.amount / leaveAnytime?.term;
  const leaveAnytimeSubscriptionAmount =
    leaveAnytime?.payment?.subscription?.amount;
  const cancelAnytimeAmount = cancelAnytime?.payment?.amount;

  const leaveAnytimeTotal = formatAmount(rent + leaveAnytimeAmount);
  const leaveAnytimeSubscriptionTotal = formatAmount(
    rent + leaveAnytimeSubscriptionAmount
  );
  const cancelAnytimeTotal = formatAmount(cancelAnytimeAmount);

  return (
    <div className='flex flex-col w-full h-full'>
      <ApplyTitle
        title='Choose Coverage'
        subtitle='Select the coverages and options for your plan.'
        buttons={
          <ApplyButtons
            isValid={
              coverageSelection[coverageTypes.CANCEL_ANYTIME] ||
              coverageSelection[coverageTypes.LEAVE_ANYTIME]
            }
            handleSubmit={onNextClick}
            onNextClick={onNextClick}
            onBackClick={onBackClick}
          />
        }
      />

      {/* main content */}
      <ApplyContent>
        {isGettingSinglePlan || isGettingPlan ? (
          <Flex className='flex-col gap-12'>
            <Skeleton className='rounded-md h-[10dvh] w-full' />
            <Flex className='w-full gap-12'>
              <Skeleton className='rounded-md h-[40dvh] w-full' />
              <Skeleton className='rounded-md h-[40dvh] w-full' />
            </Flex>
            <Skeleton className='rounded-md h-[40dvh] w-full' />
            <Skeleton className='rounded-md h-[40dvh] w-full' />
          </Flex>
        ) : isEmpty(plan) ? (
          <Flex className='border-gray-200 rounded-md w-full h-[60dvh] flex items-center justify-center'>
            <Text className='text-lg font-medium'>
              No plan found. Please go back and select a plan.
            </Text>
          </Flex>
        ) : (
          <>
            {/* container */}
            <Flex className='w-full flex-col gap-12'>
              {/* plan card and info */}
              <Flex className='flex-col gap-6'>
                {/* plan heading */}
                <Flex className='flex-col gap-1 w-full items-left'>
                  <h2 className='text-lg font-medium'>Review Plan Details</h2>
                  <Text className='text-base text-gray-500'>
                    Please review the plan details below to ensure they match
                    your lease agreement. If any changes are needed, click the
                    button below to go back and update your lease information.
                  </Text>
                </Flex>

                {/* plan info card */}
                <Flex className='flex-col gap-4 w-full'>
                  <PlanInfoCard
                    plan={plan}
                    member={userDetails}
                    selectedCancelAnytime={
                      coverageSelection[coverageTypes.CANCEL_ANYTIME]
                    }
                    selectedLeaveAnytime={
                      coverageSelection[coverageTypes.LEAVE_ANYTIME]
                    }
                  />
                  <Button
                    onClick={onBackClick}
                    theme='secondary'
                    title='Update Lease Information'
                  />
                </Flex>
              </Flex>

              {/* plan coverages section */}
              <Flex className='flex-col gap-6'>
                {/* coverages heading */}
                <Flex className='flex-col gap-1 w-full items-left'>
                  <h2 className='text-lg font-medium'>
                    Select Coverages For Your Plan
                  </h2>
                  <Text className='text-base text-gray-500'>
                    Select the Coverages you would like to include in your plan.
                  </Text>
                </Flex>                

                {/* Leave Anytime */}
                {leaveAnytimeEligible ? (
                  <PrePurchaseCoverageCard
                    coverageType={coverageTypes.LEAVE_ANYTIME}
                    amount={
                      <CoveragePrice
                        amount={
                          leaveAnytime?.payment?.onetime?.amount /
                          leaveAnytime?.term
                        }
                        coverageType={coverageTypes.LEAVE_ANYTIME}
                      />
                    }
                    options={
                        <RadioGroup
                          className='flex flex-col  divide-y divide-gray-200'
                          defaultValue={
                            coverageSelection[coverageTypes.LEAVE_ANYTIME] ||
                            planPaymentMode.ONE_TIME
                          }
                          onChange={(value) => {
                            dispatch(
                              saveCoverageSelection({
                                ...coverageSelection,
                                [coverageTypes.LEAVE_ANYTIME]: value,
                              })
                            );
                          }}
                        >
                          <PaymentPlanCard
                            title={
                              <Flex className='items-center gap-2'>
                                <Text>Pay upfront</Text>
                                <Tag colorScheme='blue' size='sm'>
                                  Best Deal
                                </Tag>
                              </Flex>
                            }
                            subtitle={`Pay ${formatAmount(
                              leaveAnytime?.payment?.onetime?.amount
                            )} upfront for the entire coverage period`}
                            amount={leaveAnytimeTotal}
                            // discount={
                            //   leaveAnytime?.payment?.onetime
                            //     ?.discountPercentage
                            // }
                            value={planPaymentMode.ONE_TIME}
                          />
                          <PaymentPlanCard
                            title='Pay monthly'
                            subtitle={`Pay ${formatAmount(
                              leaveAnytime?.payment?.subscription?.amount
                            )} for ${
                              leaveAnytime?.term
                            } months from ${formatDateToHumanDate(
                              leaveAnytime?.startDate
                            )} to ${formatDateToHumanDate(
                              leaveAnytime?.endDate
                            )}`}
                            amount={leaveAnytimeSubscriptionTotal}
                            value={planPaymentMode.SUBSCRIPTION}
                          />
                          <PaymentPlanCard
                            title='No thanks'
                            subtitle='You could be responsible for the full cost of your lease if you leave early'
                            type={planPaymentMode.ONE_TIME}
                            amount={formatAmount(rent)}
                            value={''}
                          />
                        </RadioGroup>
                    }
                  >
                    <InfoDetail
                      title='Coverage Period'
                      subtitle={`${formatDateToHumanDate(
                        leaveAnytime?.startDate
                      )} - ${formatDateToHumanDate(leaveAnytime?.endDate)}`}
                    >
                      {hasLimitedCoverage ? (
                        <Tag colorScheme='yellow' className='py-1 w-fit'>
                          <Icon as={FaExclamationCircle} className='mr-2' />
                          Your coverage is limited and doesn’t extend through
                          your entire lease agreement. Please contact us for
                          more information.
                        </Tag>
                      ) : null}
                      <Text>
                        Your coverage period starts on the date you purchase
                        your plan and ends on the date your lease starts. You
                        are eligible to leave your lease at any time during this
                        period and receive benefits.
                      </Text>
                    </InfoDetail>
                    {program?.deductible ? (
                      <InfoDetail
                        title='Deductible'
                        subtitle={FormatAsCurrency(program?.deductible, 'USD')}
                      >
                        <Text>
                          The deductible is the amount you must pay out of
                          pocket before your coverage begins. For example, if
                          your deductible is $500, you must pay $500 out of
                          pocket before your coverage begins.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {concessions > 0 ? (
                      <InfoDetail
                        title='Non-Covered Expenses'
                        subtitle={FormatAsCurrency(concessions, 'USD')}
                      >
                        <Text>
                          ReLease does not cover the{' '}
                          {FormatAsCurrency(concessions, 'USD')} in concessions
                          you received from your landlord as part of this lease
                          agreement.
                        </Text>

                        <Text>
                          ReLease does not cover any concessions or discounts
                          you receive from your landlord. Any non-covered
                          expenses will need to be paid out of pocket prior to
                          receiving benefits. ReLease does not cover or change
                          any terms related to cleaning fees, pet fees, or other
                          fees that are not part of your lease agreement.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {program?.requiredStay ? (
                      <InfoDetail
                        title='Required Stay'
                        subtitle={`${program?.requiredStay} months`}
                      >
                        <Text>
                          You must stay in your lease for a minimum of{' '}
                          {program?.requiredStay} months before you are eligible
                          to leave your lease using this coverage. If you leave
                          before this time, you will not be eligible to receive
                          benefits.
                        </Text>
                      </InfoDetail>
                    ) : null}
                  </PrePurchaseCoverageCard>
                ) : null}

                {/* Cancel Anytime */}
                {/* Only show cancel anytime if the term is greater than one month (or else there's no value) */}
                {cancelAnytimeEligible ? (
                  <>
                    <Flex className='flex-col gap-1 w-full items-left'>
                      <h2 className='text-lg font-medium'>Recommended Additional Coverage</h2>
                      <Text className='text-base text-gray-500'>
                        We recommend adding extra coverage because your lease was signed over two months before your move-in date.
                      </Text>
                    </Flex>
                    <PrePurchaseCoverageCard
                    coverageType={coverageTypes.CANCEL_ANYTIME}
                    amount={
                      <CoveragePrice
                        amount={cancelAnytime?.payment?.amount}
                        coverageType={coverageTypes.CANCEL_ANYTIME}
                      />
                    }                    
                    options={
                      <RadioGroup
                        onChange={(value) =>
                          dispatch(
                            saveCoverageSelection({
                              ...coverageSelection,
                              [coverageTypes.CANCEL_ANYTIME]: value,
                            })
                          )
                        }
                        value={coverageSelection[coverageTypes.CANCEL_ANYTIME]}
                        className='flex flex-col divide-y divide-gray-200'
                      >
                        <PaymentPlanCard
                          title='Add additional coverage'
                          subtitle={`Pay ${formatAmount(
                            cancelAnytime?.payment?.amount
                          )} upfront for the entire coverage period`}
                          amount={cancelAnytimeTotal}
                          recurring={false}
                          value={coverageTypes.CANCEL_ANYTIME}
                        />
                        <PaymentPlanCard
                          title='No thanks'
                          subtitle='You could be responsible for the full cost of your lease if you cancel early'
                          recurring={false}
                          value={''}
                        />
                      </RadioGroup>
                    }
                  >
                    <InfoDetail
                      title='Coverage Period'
                      subtitle={`${formatDateToHumanDate(
                        cancelAnytime?.startDate
                      )} - ${formatDateToHumanDate(cancelAnytime?.endDate)}`}
                    >
                      <Text>
                        Your coverage period starts on the date you purchase
                        your plan and ends on the date your lease starts. You
                        are eligible to cancel your lease at any time during
                        this period and receive benefits.
                      </Text>
                    </InfoDetail>
                    {program?.deductible ? (
                      <InfoDetail
                        title='Deductible'
                        subtitle={FormatAsCurrency(program?.deductible, 'USD')}
                      >
                        <Text>
                          The deductible is the amount you must pay out of
                          pocket before your coverage begins. For example, if
                          your deductible is $500, you must pay $500 out of
                          pocket before your coverage begins.
                        </Text>
                      </InfoDetail>
                    ) : null}
                    {concessions > 0 ? (
                      <InfoDetail
                        title='Non-Covered Expenses'
                        subtitle={FormatAsCurrency(concessions, 'USD')}
                      >
                        <Text>
                          ReLease does not cover the{' '}
                          {FormatAsCurrency(concessions, 'USD')} in concessions
                          you received from your landlord as part of this lease
                          agreement.
                        </Text>

                        <Text>
                          ReLease does not cover any concessions or discounts
                          you receive from your landlord. Any non-covered
                          expenses will need to be paid out of pocket prior to
                          receiving benefits. ReLease does not cover or change
                          any terms related to cleaning fees, pet fees, or other
                          fees that are not part of your lease agreement.
                        </Text>
                      </InfoDetail>
                    ) : null}
                  </PrePurchaseCoverageCard>
                  </>
                ) : null}

                {/* Neither coverage is available */}
                {!cancelAnytimeEligible && !leaveAnytimeEligible && (
                  <EmptyPage
                    PageIcon={FaCircleXmark}
                    title='No Coverages Available'
                    subtitle='You are not eligible for any coverages at this time.'
                    buttonText={'Cancel Plan Application'}
                    onClick={toggleCancel}
                  />
                )}
              </Flex>

              <Flex className='flex-col gap-2 w-full items-left text-left'>
                <Button
                  disabled={
                    !coverageSelection[coverageTypes.CANCEL_ANYTIME] &&
                    !coverageSelection[coverageTypes.LEAVE_ANYTIME]
                  }
                  onClick={onNextClick}
                  theme='primary'
                  title='Continue to Payment'
                />
              </Flex>

              <DisclosureSection />
            </Flex>
          </>
        )}
      </ApplyContent>
    </div>
  );
};

export default PlanStepChooseCoverage;
