import {
  Flex, Text
} from '@chakra-ui/react';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import { MemberInfoCard } from 'components/shared/member-info-card';
import { coverageTypes } from 'utils/enums';

const PrePurchaseCoverageCard = ({
  coverageType,
  amount,
  children,
  options,
}) => {
  const coverageDetails = [
    {
      value: coverageTypes.CANCEL_ANYTIME,
      name: 'Cancel Anytime',
      description:
        'Cancel your upcoming lease any time during your coverage period.',
      url: 'https://www.releaserent.com/legal/release-cancel-anytime-terms-conditions',
    },
    {
      value: coverageTypes.LEAVE_ANYTIME,
      name: 'Leave Anytime',
      description:
        'Leave your current lease any time during your coverage period.',
      url: 'https://www.releaserent.com/legal/release-leave-anytime-terms-conditions',
    },
  ];

  const coverage = coverageDetails.find(
    (detail) => detail.value === coverageType
  );
  if (!coverage) return null;

  return (
    <MemberInfoCard
      title={coverage.name}
      subtitle={coverage.description}
      amount={amount}
    >
      {children}

      {/* Payment Options */}
      {options ? (
        <Flex className='flex-col gap-4 border-t border-gray-200'>
          {options}
        </Flex>
      ) : null}
    </MemberInfoCard>
  );
};

const PostPurchaseCoverageCard = ({ coverageType, children }) => {
  const coverageDetails = [
    {
      value: coverageTypes.CANCEL_ANYTIME,
      name: 'Cancel Anytime',
      description:
        'Cancel your upcoming lease any time during your coverage period.',
      url: 'https://www.releaserent.com/legal/release-cancel-anytime-terms-conditions',
    },
    {
      value: coverageTypes.LEAVE_ANYTIME,
      name: 'Leave Anytime',
      description:
        'Leave your current lease any time during your coverage period.',
      url: 'https://www.releaserent.com/legal/release-leave-anytime-terms-conditions',
    },
  ];

  const coverage = coverageDetails.find(
    (detail) => detail.value === coverageType
  );
  if (!coverage) return null;

  return (
    <MemberInfoCard
      title={coverage.name}
      subtitle={coverage.description}
      buttonText='View Terms & Conditions'
      onClick={() => window.open(coverage.url, '_blank')}
    >
      {children}
    </MemberInfoCard>
  );
};

const CoveragePrice = ({ amount, coverageType }) => {
  return (
    <Flex className={`flex-col min-w-fit self-start`}>
      {coverageType === coverageTypes.LEAVE_ANYTIME && (
        <Text className=' text-gray-500 text-sm'>
          Starting From
        </Text>
      )}
      <Flex className='items-end gap-2 align-baseline'>
        <Text className='text-3xl font-medium'>
          {FormatAsCurrency(amount, 'USD')}
        </Text>
        <Text className='text-base text-gray-500 pb-1'>
          {coverageType === coverageTypes.LEAVE_ANYTIME ? 'per month' : 'total'}
        </Text>
      </Flex>
    </Flex>
  );
};

export {
  PostPurchaseCoverageCard,
  PrePurchaseCoverageCard,
  CoveragePrice,
};
