import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WrapInputLabel from 'common/components/wrap-input-label';
import { claimStatusEnum, planStatusEnum } from 'common/utils/enums';
import {
  useGetAllPlansQuery,
  useCreateClaimMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import SelectInput from 'common/components/select-input';
import StatusLabel from 'common/components/status-label';
import ApplyButtons from 'layouts/apply-buttons';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'common/layouts/apply-form';
import { Tag, Text } from '@chakra-ui/react';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { isWithinDateRange } from 'common/utils/dateRange';

const StartClaim = () => {
  const navigate = useNavigate();
  // HOOKS
  const { data: plansData, isLoading: getPlansLoading } = useGetAllPlansQuery();
  const [createClaim, { data: claimData, isLoading: createClaimLoading }] =
    useCreateClaimMutation();

  const [planId, setPlanId] = useState(null);

  // Get current date for checking eligibility
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setNow(new Date()), 1000); // updates every second
    return () => clearInterval(timer);
  }, []);

  // HANDLERS
  const hasCoverage = (coverage) =>
    coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    coverage?.leaveAnytime?.payment?.subscription?.stripe?.id ||
    coverage?.cancelAnytime?.payment?.stripe?.id;

  const isLeaveAnytimeEligible = (coverage, now) =>
    isWithinDateRange(coverage?.leaveAnytime, now);

  const isCancelAnytimeEligible = (coverage, now) =>
    isWithinDateRange(coverage?.cancelAnytime, now);

  const createPlansOptionMenu = (obj) => {
    // Check if obj is empty
    if (isEmpty(obj)) return [];

    // Remove items in obj if their status is not active
    const filteredItems = obj.filter(
      (item) => item.status === planStatusEnum.ACTIVE
    );

    // Check if filteredItems is empty
    if (isEmpty(filteredItems)) return [];

    return filteredItems?.map(
      ({ createdAt, id, status, lease, coverage, ...rest }) => {
        const leaveEligible = isLeaveAnytimeEligible(coverage, now);
        const cancelEligible = isCancelAnytimeEligible(coverage, now);
        const coverageExists = hasCoverage(coverage);
        const coverageEligible = leaveEligible || cancelEligible;

        return {
          label: (
            <div className='w-full flex py-1 justify-between flex-col sm:gap-2 sm:flex-row sm:items-center'>
              <div className='text-md font-medium'>Plan {id.toUpperCase()}</div>
              <div className='text-md flex gap-2 sm:items-center w-full justify-between sm:w-auto flex-col sm:flex-row'>
                {lease?.address && (
                  <div className='text-md text-gray-500'>
                    {lease.address.streetAddress1}, {lease.address.city}
                  </div>
                )}
                {!coverageExists || !coverageEligible ? (
                  <div>
                    <Tag colorScheme='red' size='sm'>
                      No Eligible Coverage
                    </Tag>
                  </div>
                ) : (
                  <StatusLabel status={status} colorScheme='green' size='sm' />
                )}
              </div>
            </div>
          ),
          value: id,
          hasCoverage: coverageExists,
          leaveAnytimeEligible: leaveEligible,
          cancelAnytimeEligible: cancelEligible,
          ...rest,
        };
      }
    );
  };

  const options = useMemo(() => createPlansOptionMenu(plansData), [plansData]);

  return (
    <div className='flex flex-col w-full h-full'>
      <ApplyTitle
        title='Select Plan for Claim'
        subtitle='Pick the plan for the lease you are submitting a claim for.'
        buttons={
          <ApplyButtons
            isValid={planId}
            moveOn={true}
            onNextClick={() => {
              createClaim({
                value: { 
                  planID: planId,
                },
              })
                .unwrap()
                .then((claim) => {
                  console.log('claim: ', claim);
                  navigate(`/dashboard/claims/submit/${claim.data.id}`);
                });
            }}
            disableBack
          />
        }
      />

      {/* Main Area */}
      <ApplyContent>
        {/* container */}
        <ApplyFormContainer>
          {/* select plan */}
          <ApplyFormLayout>
            <Text className='font-medium col-span-2'>
              Select Plan from List
            </Text>
            <ApplyFormField>
              <WrapInputLabel isRequired>Plan</WrapInputLabel>

              <SelectInput
                options={options}
                isDisabled={isEmpty(plansData) || getPlansLoading}
                placeholder={
                  getPlansLoading
                    ? 'Fetching...'
                    : isEmpty(plansData)
                    ? `No available plan, please create a new one.`
                    : 'Type to search for a plan by number'
                }
                name='planId'
                value={options.find(
                  (selectedPlan) => selectedPlan.value === planId
                )}
                onChange={(selectedOption) => {
                  setPlanId(selectedOption.value);
                }}
                isOptionDisabled={(option) => {
                  const noCoverage = !option.hasCoverage;
                  const notEligible =
                    !option.leaveAnytimeEligible &&
                    !option.cancelAnytimeEligible;
                  return notEligible || noCoverage;
                }}
              />
            </ApplyFormField>
          </ApplyFormLayout>
        </ApplyFormContainer>
      </ApplyContent>
    </div>
  );
};

export default StartClaim;
