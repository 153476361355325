import { SetupCard } from 'common/components/setup-card';
import ModalBgLayout from 'common/layouts/modal-bg';
import React from 'react';
import BgLayout from 'common/components/bg';

const ErrorPageLayout = ({ 
  background,
  title='There was an issue logging you in',
  subtitle='Please refresh the page and try again',
  message="We're having trouble logging you in. Please refresh the page and try again.",
  buttonTitle='Go to Login',
  onClick=() => window.location.replace('/login')
}) => {
  return (
    <BgLayout>
      <ModalBgLayout>
        <SetupCard
          title={title}
          subtitle={subtitle}
          message={message}
          buttonTitle={buttonTitle}
          onClick={onClick}
        />
      </ModalBgLayout>
      {background}
    </BgLayout>
  );
};

export default ErrorPageLayout;
