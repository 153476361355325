import AuthLabel from 'common/components/auth-label';
import Button from 'common/components/button';
import FormError from 'common/components/form-error';
import LogoImage from 'common/components/logo-image';
import PasswordInput from 'common/components/password-input';
import WrapInputLabel from 'common/components/wrap-input-label';
import { Form, Formik } from 'formik';
import AuthMainLayout from 'layouts/auth-main';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useResetPasswordMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import * as Yup from 'yup';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from 'utils/regex';
import Tracker from 'components/auth/reset-password/tracker';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { ApplyFormField } from 'common/layouts/apply-form';
import { PasswordRequirements } from 'common/components/password-requirements';

const ResetPassword = () => {
  // HOOKS
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get('token');
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        password: '',
        repeatPassword: '',
      }}
      onSubmit={(value) => {
        trackFormSubmission({});
        resetPassword({
          password: value.password,
          token: tokenParam,
        })
          .unwrap()
          .then((data) => {
            trackFormSubmissionSuccess({});
            toast.success(data?.status || 'Success!', {
              toastId: 'resetPasswordSuccess',
            });
            setTimeout(() => {
              navigate('/reset-password-success');
            }, 100);
          })
          .catch((e) => {
            trackFormSubmissionError({}, e?.data?.msg || 'Reset email failed');
            toast.error(e?.data?.msg || 'Something went wrong!', {
              toastId: 'resetPasswordErrorr',
            });
          });
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required('Required')
          .min(8, 'Must be at least 8 characters long')
          .matches(NOT_STRICT_DIGIT_REGEX, 'Must contain a number')
          .matches(LOWERCASECASE_REGEX, 'Must contain a lowercase')
          .matches(UPPERCASE_REGEX, 'Must contain an uppercase'),
        repeatPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('password')], 'Passwords must match'),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <AuthMainLayout>
            <div className='px-6 md:px-8 flex flex-col gap-12'>

              {/* labels */}
              <div className='flex gap-3 items-center mobile:gap-0'>
                <AuthLabel title='Log in' />
                <Link to='/signup'>
                  <AuthLabel title='Sign up' isActive={false} />
                </Link>
              </div>

              {/* Form */}
              <Form className='flex flex-col items-stretch w-full gap-12'>
                {/* caption */}
                <div className='flex flex-col w-full gap-6'>
                  <p className='text-4xl font-display tracking-tight'>
                    Set a New Password
                  </p>
                  <p className='font-normal text-base text-gray-500'>
                    Create a new password to log into your ReLease account with.
                  </p>
                </div>

                {/* fields container */}
                <div className='w-full flex flex-col gap-6'>
                  {/* password */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                        New Password:
                      </WrapInputLabel>
                    <PasswordInput
                      name='password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('password');
                        handleChange(e);
                      }}
                      value={values.password}
                      placeholderText='New password'
                    />
                    <FormError name='password' />
                    <PasswordRequirements password={values.password} />
                  </ApplyFormField>

                  {/*Re-enter password */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                        Confirm Password:
                      </WrapInputLabel>
                    <PasswordInput
                      name='repeatPassword'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('repeatPassword');
                        handleChange(e);
                      }}
                      value={values.repeatPassword}
                      placeholderText='Re-enter new password'
                    />
                    <FormError name='repeatPassword' />
                  </ApplyFormField>

                  {/* button */}
                <div className='flex flex-col gap-3'>
                  <Button
                    loading={isLoading}
                    disabled={!isValid || isLoading || isSuccess}
                    type='submit'
                    title='Update Password'
                  />
                </div>
                </div>                
                <Tracker
                  values={values}
                  errors={errors}
                  touched={touched}
                  formName='requestResetPassword'
                />
              </Form>
            </div>
          </AuthMainLayout>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
