// Component for custom radio buttons based on Chakra UI for yes/no (true/false) values in forms
// Most common use case: disclosure questions for high risk applicants (e.g. are you on an expiring visa?)

import {
  Box,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'

// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const radio = getRadioProps()

  return (
    <Box as="label">
      <input {...input} className='hidden' />
      <Box
        {...radio}
        className={`min-w-fit cursor-pointer px-4 py-1 text-base font-medium transition-all duration-200 ${
          props.isChecked
            ? 'bg-gray-100 font-medium'
            : 'bg-white font-normal text-gray-500 hover:bg-gray-50 hover:text-black'
        }`}
      >
        {props.children}
      </Box>
    </Box>
  )
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
function EnumRadioGroup({ value, name, onChange, className, enumOptions }) {
  const options = enumOptions

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange,
  })

  const group = getRootProps()

  return (
    <div {...group} className={`grid grid-cols-2 min-w-fit border rounded-md overflow-hidden w-fit divide-x divide-gray-200 ${className}`}>
      {options.map((option) => {
        const radio = getRadioProps({ value: option.value, label: option.label })
        return (
          <RadioCard key={option.value} {...radio}>
            {option.label}
          </RadioCard>
        )
      })}
    </div>
  )
}

export default EnumRadioGroup;